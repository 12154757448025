import React, { Component } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { Button } from "react-bootstrap";
import {
  textFilter,
  selectFilter,
  dateFilter,
  numberFilter,
  Comparator
} from "react-bootstrap-table2-filter";

import queryString from "query-string";
import MagicTable from "../Functional/magictable";

import {multiWordFilter,barcodeFilter} from "../multiwordfilter.js";

export class StockSales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: null,
      records: null,
      from: null,
      to: null,
      title: null,
      subtitle: null,
      supplierid: 1,
      days: 10,
      first: true,
      progress: 0,
    };
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  componentDidMount() {
    axios
      .get(this.props.baseUrl + "suppliers/", {
        headers: { Authorization: `Bearer ` + this.props.jwt },
      })
      .then((response) => {
        console.log(response.data.records);

        var a = [];

        response.data.records.forEach(function (element) {
          a.push({ value: element.name, label: element.name });
        });

        this.setState({ selectOptionssupplier: a });
      });

    axios
      .get(this.props.baseUrl + "department/", {
        headers: { Authorization: `Bearer ` + this.props.jwt },
      })
      .then((response) => {
        console.log(response.data.records);

        var a = [];

        response.data.records.forEach(function (element) {
          a.push({ value: element.name, label: element.name });
        });

        this.setState({ selectOptionsdepartments: a });
      });

    this.setState({ supplierid: this.getCookie("supplier") });
    this.setState({ days: this.getCookie("days") });

    if (!this.state.days) this.setState({ days: 10 });

    if (!this.state.supplierid) this.setState({ days: 1 });

    this.refresh();
  }

  componentDidUpdate() {}

  refresh() {
    var url = "";

    const searchvalues = queryString.parse(this.props.location.search);
    var start = searchvalues.from;
    var end = searchvalues.to;

    url =
      this.props.baseUrl +
      "stockreport/supplier2/?start=" +
      start +
      "&end=" +
      end;

    console.log("Requesting .." + url);

    this.setState({ records: null });
    axios
      .get(url, {
        headers: { Authorization: `Bearer ` + this.props.jwt },
        onDownloadProgress: (progressEvent) => {
          this.setState({ progress: progressEvent.loaded });
        },
      })
      .then((response) => {
        console.log("data is ..");
        console.log(response);

        this.setState({ records: response.data.records });

        if (response.data.count !== "0") {
          this.setState({
            subtitle: "Stock name: " + response.data.records[0].stock_name,
          });
        }

        this.forceUpdate();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    if (this.state.records === null)
      return (
        <div>
          <form></form>
          <h2>Generating your report</h2>
          <p>
            This may take around 30s or so as it now returns all suppliers in a
            single report
          </p>
          <p>Please wait......</p>
          <p>Loaded {this.state.progress} bytes</p>
          <ReactLoading
            type={"spinningBubbles"}
            color={"#f00"}
            height={"50%"}
            width={"100%"}
          />
        </div>
      );


    const columns = [
      {
        dataField: "id",
        text: "id",
        hidden: true,
      },
      {
        dataField: "barcode",
        text: "Barcode",
        sort: true,
        sortValue: (cell, row) => Number(cell),
        filter: textFilter({
          onFilter: barcodeFilter
        }),
      },
      {
        dataField: "mpn",
        text: "mpn",
        sort: true,
        filter: textFilter({
          onFilter: multiWordFilter,
        }),
      },
      {
        dataField: "stock_name",
        text: "Stock name",
        sort: true,
        filter: textFilter({
          onFilter: multiWordFilter,
        }),
      },
      {
        dataField: "stock_quatity",
        text: "In Stock",
        sort: true,
        formatter: (cell, row) => Number(cell),
        sortValue: (cell, row) => Number(cell),
        filter: numberFilter(),
      },
      {
        dataField: "salescount",
        text: "Sales",
        sort: true,
        formatter: (cell, row) => Number(cell),
        sortValue: (cell, row) => Number(cell),
        filter: numberFilter(),
        style: { backgroundColor: "#FCF" },
      },
      {
        dataField: "total",
        text: "Total taken",
        sort: true,
        formatter: (cell, row) => Number(cell),
        sortValue: (cell, row) => Number(cell),
        filter: numberFilter(),
        style: { backgroundColor: "#FCF" },
      },
      {
        dataField: "company_price",
        text: "Cost price",
        sort: true,
        filter: numberFilter(),
      },
      {
        dataField: "selling_price",
        text: "Selling price",
        sort: true,
        sortValue: (cell, row) => Number(cell),
        filter: numberFilter(),
      },
      {
        dataField: "date",
        text: "Last Stock take",
        sort: true,
        hidden: false,
        filter: dateFilter({}),
      },
      {
        dataField: "lastsale",
        text: "Last sale",
        sort: true,
        hidden: false,
        filter: dateFilter({}),
        style: { backgroundColor: "#FCF" },
      },
      {
        dataField: "departmentname",
        text: "Department",
        sort: true,
        hidden: false,
        filter: selectFilter({
          options: this.state.selectOptionsdepartments,
        }),
      },
      {
        dataField: "suppliername",
        text: "Supplier",
        sort: true,
        hidden: false,
        filter: selectFilter({
          options: this.state.selectOptionssupplier,
        }),
      },
    ];

    return (
      <div>
        <MagicTable
          {...this.props}
          data={this.state.records}
          columns={columns}
        />
      </div>
    );
  }

  
}

export default StockSales;
