import { filter } from "underscore";

export function mynumberfilter (filterVal, data)
 {
  if (!filterVal) {
    return data;
  }

  console.log(filterVal);

  var comparitor = filterVal.comparitor;
  var num = parseInt(filterVal.number);

  switch(comparitor)
  {
      case '<':
        break;
      case '>':
          break;
      case '=':
          break;
      case '>=':
          break;
      case '<=':
           break;
  }

 }

export function multiWordFilter (filterVal, data)
 {


  if (!filterVal) {
      return data;
    }

    filterVal = filterVal.toLowerCase();
    filterVal = filterVal.replaceAll("é", "e");
    filterVal = filterVal.replaceAll("-", "");
    filterVal = filterVal.replaceAll("s", "");

    const filterWords = filterVal.split(" ");

    return data.filter((row) => {
      return filterWords.every((word) => {
        return Object.values(row).some((value) => {
          let x = String(value);
          x = x.replaceAll("é", "e");
          x = x.replaceAll("-", "");
          x = x.replaceAll("s", "");
          
          return x.toLowerCase().includes(word);
        });
      });
    });
  };

  export function barcodeFilter (column,filterVal, data)
 {
    if (!filterVal) {
      return data;
    }

    console.log("BARCODE FILTER");
    console.log(column);


    filterVal = filterVal.toLowerCase();

    filterVal = filterVal.replace(/\D/g, '');

    if(filterVal[0] === '0')
        filterVal = filterVal.replace("0","");
 


    const filterWords = filterVal.split(" ");

    return data.filter((row) => {
      return filterWords.every((word) => {
        return Object.values(row).some((value) => {
          let x = String(value);
          return x.toLowerCase().includes(word);
        });
      });
    });
  };