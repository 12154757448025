import React, { Component } from 'react'

import {Button,Form} from 'react-bootstrap';


import { withRouter } from 'react-router-dom';
import axios from 'axios';

export class AddSupplier extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
           suppliername: "",
           hasErrors: false,
           formErrors_suppliername: '',
        };
    }

    componentDidMount()
    {
        console.log(this.props.jwt);
    }

    handleSubmit()
    {

        this.setState({ hasErrors: false });
        this.setState({ formErrors_suppliername: '' });

      if(this.state.suppliername === '' || this.state.suppliername === null)
      {
        this.setState({ hasErrors: true });
        this.setState({ formErrors_suppliername: 'Must not be empty' });
        return;
      }


        axios(
        {
            method: 'post',
            url: this.props.baseUrl+"suppliers/",
            data: {
              name: this.state.suppliername, 
            },
            headers:
            {
              Authorization: `Bearer `+this.props.jwt,
            }
        }).then(response => 
        {    
            console.log("SUBMIT RESPOSE");
           
            
            console.log(response.data);
        
            if(response.data['error_info'][0] !== '00000')
            {
              this.setState({Title: response.data['error_info'][2],msg: 'Error'});
            }
            else
            {
              this.setState({ok: true});
              this.setState({suppliername: ''});
              this.props.onreload();
            }  
          })
          .catch(function (error) 
          {
         
            console.log("SUBMIT ERROR");       
            console.log(error);
          })

     

    }

    onChangeSupplierName(e)
    {
      this.setState({suppliername: e.target.value});
    }

    
  errorClass(error) {

    if (!error)
      return '';
    return (error.length === 0 ? '' : 'has-error');
  }

    render() 
    {
        return (
            <div>
            <h3>Add Suppliers</h3>

            <Form>
                <Form.Group controlId="SupplierName" className={` ${this.errorClass(this.state.formErrors_suppliername)}`}>                
                    <Form.Label>Supplier Name</Form.Label>
                    <Form.Control type="text" value={this.state.suppliername} onChange={this.onChangeSupplierName.bind(this)}/> 
                    <div className="ErrMsg"> {this.state.formErrors_suppliername}</div>
                </Form.Group>

                <Button variant="primary" onClick={this.handleSubmit.bind(this)} > 
                    Submit
                </Button>
 
            </Form>

            </div>
        );
    }
}

export default withRouter(AddSupplier)


    