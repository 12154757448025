import React, { Component } from 'react'
import axios from 'axios';
import ItemList from '../ItemList';
import queryString from 'query-string';
import { PieChart } from 'react-minimal-pie-chart';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TicketTotal } from '../TicketTotal.js';
import distinctColors from 'distinct-colors'
import { SalesByDptGraph } from '../SalesByDptGraph';

function mytoFixed(value, precision) {
  var power = Math.pow(10, precision || 0);
  return String(Math.round(value * power) / power);
}

export class GetSales extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
            departments: null,
            bydepartment: null,
            from: null,
            to: null,
            piechartdata: null,
            totalSales_amt: null,
            totalSales_cnt: null,
            fetchrequested: false,
            bydepartment2: null,
            bydepartmenttotal: null,
            
            palette: null,
            colors: null,
            searchok: null,

        };
    }

    componentDidMount()
    {
      
    var p = distinctColors({count: 15 });

    var c = [];
    var i=1;

    p.forEach(function(element){ 
      var colorstring = "#"+Math.round(p[i-1]._rgb[0]).toString(16) + Math.round(p[i-1]._rgb[1]).toString(16) + Math.round(p[i-1]._rgb[2]).toString(16);
      c[i] = colorstring; 
      i++;
    });

    this.setState({palette: p });
    this.setState({colors: c });

      

    if(this.props.location.search==="")
      return;

    this.setState({searchok: 1});

    const searchvalues = queryString.parse(this.props.location.search)
    this.setState({from: searchvalues.from});
    this.setState({to: searchvalues.to});

    axios.get(this.props.baseUrl+'sales?start='+searchvalues.from+'&end='+searchvalues.to , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      //console.log("we have records for sales");
      this.setState({records: response.data.records});
      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })

    axios.get(this.props.baseUrl+'department/' , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
    //  console.log("we have records for departments");
      this.setState({departments: response.data.records});
      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })



    axios.get(this.props.baseUrl+'sales?bydpt&start='+searchvalues.from+'&end='+searchvalues.to , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
    
      this.setState({bydepartment: response.data.records});

      if(this.state.bydepartment!== null)
      {
        var chartdata =[];

        var totalSales_amt=0;
        var totalSales_cnt=0;
        this.state.bydepartment.forEach(function(element){  
          totalSales_amt += Number(element.total);
          totalSales_cnt += Number(element.count);
        });
  
  
        var self = this;


        this.state.bydepartment.forEach(function(element){ 
          var pct = 100.0*(Number(element.total) / totalSales_amt);   
          var i = element.department;

          chartdata.push({ title: element.name, value: pct, color: self.state.colors[i], total: element.total }) 
  
        });



        chartdata.sort((b,a) => a.value - b.value);


        this.setState({piechartdata: chartdata});
        this.setState({totalSales_amt: totalSales_amt});
        this.setState({totalSales_cnt: totalSales_cnt});
        
    }
  

    this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })

    }

   
  render() {

    /*
    if(this.state.records === null || this.state.departments === null || this.state.piechartdata === null)
        return(
            <div> 
            <h2>Generating your report</h2>
            <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
            </div>
        );
*/
  
    var key=0;

    return (
      <div>

      {this.state.totalSales_amt &&
      <div>
    <h1>Sales Report</h1>
    <p>Date: {this.state.from} to {this.state.to}</p>
    <p>Total sales £{mytoFixed(this.state.totalSales_amt,2)} ({this.state.totalSales_cnt})</p>
    </div>}
    
    {this.state.searchok && 
    <TicketTotal date={this.state.from} to={this.state.to} jwt={this.props.jwt} baseUrl={this.props.baseUrl}/>
    }

    {this.state.piechartdata && 
    <div>  
      <PieChart 
        style={{height: '300px'}}
        label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
        labelStyle={{
          fontSize: '5px',
          fontFamily: 'sans-serif'
        }}
        radius={42}
        labelPosition={112}
        data={this.state.piechartdata}   
        segmentsShift={2}  
        />

      <ul style={{listStyleType: 'none'}}>
        {
        
          this.state.piechartdata.map(element =>
            <li key={key++} style={{listStyle: 'none'}}> <div style={{display: 'block', float: 'left', width: '20px', height: '20px', backgroundColor: element.color, marginRight: '10px' }}></div>{mytoFixed(element.value,2)}{'%  £'}{mytoFixed(element.total,2)} {element.title}</li>
            )
        }
      </ul>
    </div>
   || 
            <div> 
            <h2>Generating your report</h2>
            <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
            </div>
   };

      <SalesByDptGraph {...this.props} />
    

      { this.state.departments &&  this.state.records &&
        this.state.departments.map(element =>
          <ItemList key={element.id} items={this.state.records} dpt={element.id} name={element.name}/>
        )    
        ||
        <div> 
            <h2>Generating your report</h2>
            <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
            </div>
      }
      
      </div>
    )
  }
}

GetSales.propTypes = {
  jwt: PropTypes.string.isRequired
};

export default withRouter(GetSales)
