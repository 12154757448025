import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import {Button,Form} from 'react-bootstrap';

import queryString from 'query-string';
import axios from 'axios';
import ReactLoading from 'react-loading';

import Modalmsg from '../modalmsg';
import Okmsg from '../Okmsg';
import BrandsDropDown from '../BrandsDropDown';
import SuppliersDropDown from './Suppliers/SuppliersDropDown';



 export class BookIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
        records: null,

        sku: null, 
        stock_name: '',       
        qty: 0,      
        invoiceid: '',
        orderid: '',
        company_price: 0,
        selling_price: 0,
        brandid: 0,
        supplierid: 0,
        hasErrors: false,  
        formErrors_stock: '', 
        formErrors_invoice: '', 
        formErrors_company_price: '',
        formErrors_selling_price: '',
        formErrors_brand: '', 
        
    }
}

componentDidMount()
 {

  const searchvalues = queryString.parse(this.props.location.search);
  this.setState({sku: searchvalues.sku});

  var url = this.props.baseUrl+"products/"+searchvalues.sku+"/";

  this.setState({invoiceid: searchvalues.invoice});
  this.setState({orderid: searchvalues.orderno});
  
  axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt}})
  .then(response => {

    if(response.data['Status']==="OK" && response.data['Count'] === 0)
    {
      console.log("NEW ITEM");
      this.setState({newitem: true, brandid: '0', mpn: '',vat: "1.00", records: true})

      this.props.history.push("EditItem"+"?sku="+this.state.sku);
      //new item
    }
    else
    {
 
        this.setState({stock_name: response.data.records[0].stock_name}); 
        this.setState({selling_price: response.data.records[0].selling_price});
        this.setState({company_price: response.data.records[0].company_price}); 
        this.setState({records: response.data.records});
        this.setState({brandid: response.data.records[0].brandid});
        this.setState({supplierid: response.data.records[0].supplierid})

        if(response.data.records[0].brandid===null)
        this.setState({brandid: '0'});


      }


  })
  .catch(function (error) {
    console.log(error);
  })

 }

 
   onChangeQty(e)
   {
    this.setState({qty: e.target.value});
   }

   onChangeName(e)
   {
    this.setState({stock_name: e.target.value});

   }

  onChangeCompanyPrice(e)
  {
    this.setState({company_price: e.target.value});
  }

  onChangeSellingPrice(e)
  {
    this.setState({selling_price: e.target.value});

  }

  onChangeBrand(e)
  {
    //console.log("onChangeBrand");
    //console.log(e);
    this.setState({brandid: e});
  }

  onChangeSupplier(e)
  {
   this.setState({supplierid: e});
  
  }


  handleSubmit()
   {

    this.setState({hasErrors: false});
    this.setState({formErrors_stock: ''});
    this.setState({formErrors_invoice: ''});
    this.setState({formErrors_company_price: ''});
    this.setState({formErrors_selling_price: ''});
    this.setState({formErrors_brand: ''});
    this.setState({formErrors_supplierid: ''});

    
    var hasErrors = false;

    if(this.state.qty==="")
    {
      this.setState({formErrors_stock: 'Must not be empty'});
      hasErrors = true;
    }

    if(isNaN(this.state.qty))
    {
      this.setState({formErrors_stock: 'Not a number'});
      hasErrors = true;   
    }

    if(this.state.qty===0)
    {
      this.setState({formErrors_stock: 'Must not be 0'});
      hasErrors = true;
    }

    if(this.state.invoiceid==="" && this.state.orderid==="")
    {
      this.setState({formErrors_invoice: 'Must have either invoice and/or order no'});
      hasErrors = true;
    }

    if(this.state.company_price === 0 )
    {
      this.setState({formErrors_company_price: 'Must not be 0'});
      hasErrors= true;
    }

    if(this.state.selling_price ===0)
    {
      this.setState({formErrors_selling_price: 'Must not be 0'});
      hasErrors= true;
    }

    if(this.state.supplierid === null || this.state.supplierid === undefined)
    {
      this.setState({formErrors_supplierid: 'Must not be blank'});
      hasErrors= true;
    }

    this.setState({hasErrors: hasErrors});

    if(hasErrors === true)
    {
      return;
    }
 
  var url;
  var method;

  if(this.state.newitem===true)
  {
    method='post';
    url = this.props.baseUrl+"products/";
  }
  else
  {
    url = this.props.baseUrl+"products/bookin/"+this.state.sku;
    method='patch';
  }

  axios({
    method: method,
    url: url,
    data: {
      barcode: this.state.sku, 
      stock_quatity: this.state.qty,
      invoiceid: this.state.invoiceid,
      orderid: this.state.orderid,
      company_price: this.state.company_price,
      selling_price: this.state.selling_price,
      stock_name: this.state.stock_name,
      supplierid: this.state.supplierid,
    },
    headers:
    {
      Authorization: `Bearer `+this.props.jwt,
    }
  }) .then(response => {

    console.log("SUBMIT RESPOSE");
    console.log(response.data);

    if(response.data['error_info'][0] !== '00000')
    {
      this.setState({Title: response.data['error_info'][2],msg: 'Error'});
    }
    else
    {
      this.setState({ok: true});
    }

  })
  .catch(function (error) {
    console.log("SUBMIT ERROR");

    console.log(error);
  })
   }

   errorClass(error) {    
     
    if(!error)
      return '';

    return(error.length === 0 ? '' : 'has-error');
 }

 onModalReturn()
{
  this.setState({msg: null,Title: ""});
}


  render() {

    if(this.state.ok===true)
    {
      return(
          <div>
            <Okmsg />
          </div>
      )
    }

    if(this.state.msg !=  null)
    {
        return(
            <div>
            <Modalmsg ret={this.onModalReturn.bind(this)} Title={this.state.msg} msg={this.state.Title}  />
            </div>
        );

    }

 
    if( this.state.records === null)
    return(
        <div> 
        <h2>Generating your report</h2>
        <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
        </div>
    );


      var header;
      if(this.state.newitem===true)
      {
          header = <h1>New Item</h1>
      }
      else
      {
        header = <h1>BOOK IN STOCK</h1>
 
      }

     return (
       <div>
         {header}
      
        <Form>

        <Form.Group controlId="formSku" className={`${this.errorClass(this.state.formErrors_sku)}`}>                
          <Form.Label>Item SKU</Form.Label>
          <Form.Control type="text" readOnly="True" value={this.state.sku} />              
        </Form.Group>

        <Form.Group controlId="formStockname" className={` ${this.errorClass(this.state.formErrors_name)}`}>
          <Form.Label>Item Name</Form.Label>
          <Form.Control type="text" value={this.state.stock_name} onChange={this.onChangeName.bind(this)} />
        </Form.Group>
   
        <Form.Group controlId="formInvoiceOrder" className={` ${this.errorClass(this.state.formErrors_invoice)}`}>
          <Form.Label>Invoice No</Form.Label>
          <Form.Control type="text"  readOnly="True"  value={this.state.invoiceid}  />
          <div className="ErrMsg"> {this.state.formErrors_invoice}</div>

          <Form.Label>Order No</Form.Label>
          <Form.Control type="text" readOnly="True"  value={this.state.orderid}  />
          <div className="ErrMsg"> {this.state.formErrors_invoice}</div>
        </Form.Group>
 
        <Form.Group controlId="formCostPrice" className={` ${this.errorClass(this.state.formError_company_price)}`}>
        <Form.Label>Cost price</Form.Label>
          <Form.Control type="text" value={this.state.company_price} onChange={this.onChangeCompanyPrice.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_company_price}</div>
        </Form.Group>
  
        <Form.Group controlId="formSellingPrice" className={` ${this.errorClass(this.state.formError_selling_price)}`}>
        <Form.Label>Selling price</Form.Label>
          <Form.Control type="text"  value={this.state.selling_price}  onChange={this.onChangeSellingPrice.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_selling_price}</div>
        </Form.Group>
  

        <Form.Group controlId="formStock" className={` ${this.errorClass(this.state.formErrors_stock)}`}>
          <Form.Label>New Stock Amount, only enter NEW stock on this delivery</Form.Label>
          <Form.Control type="text" value={this.state.qty} onChange={this.onChangeQty.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_stock}</div>
        </Form.Group>


      
        <Form.Group controlId="formSUPPLIER" className={` ${this.errorClass(this.state.formErrors_supplier)}`}>        
        <Form.Label>Supplier</Form.Label>
        <SuppliersDropDown baseUrl={this.props.baseUrl} jwt={this.props.jwt} name={this.state.supplierid} onChangeSupplier={this.onChangeSupplier.bind(this)}/>
        <div className="ErrMsg"> {this.state.formErrors_supplierid}</div>
        </Form.Group>


        <Button variant="primary" onClick={this.handleSubmit.bind(this)} > 
          Submit
        </Button>

        
      </Form>

       </div>
     )
   }
 }
 
 export default withRouter(BookIn)
 