import React, { Component } from 'react'
import {Dropdown} from 'react-bootstrap';

export class VatDropDown extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
            selected: null,

        }
    }
 
  componentDidMount()
  {
    
    var vat = [];
    vat.push({"id":"0.00","name":"Zero Vat"});
    vat.push({"id":"1.00","name":"Standard Vat"});

    this.setState({records: vat});


    console.log("VAT did mount()");
    console.log(this.props.title);


    if(this.props.title==null)
    {
      this.setState({selected: 'NONE'});
    }
    else
    {
      var title = this.props.title;
      var pthis = this;
      vat.forEach(function(element)
      {
     //     console.log(element);
          if(element.id === title)
          {
              pthis.setState({selected: element.name});
          }

      });
    }
   
    this.forceUpdate();

  }

  onUpdateNameByKey(newkey)
  {
    console.log(this);
    var pthis=this;
    this.state.records.forEach(function(element)
    {
        if(element.id === newkey)
        {
            pthis.setState({selected: element.name});
        }

    });
  }

  onChange(e)
  {

  }

  onSelect(e)
  {
    //this.setState({selected: e})
    this.onUpdateNameByKey(e);
    this.props.onChangeVat(e);
   console.log(e);
  }

  render() {

    if(this.state.records == null)
    {   
        return (<div>DD IS NULL</div>);
    }

    //console.log(this.state.records)

    return (
      <div>
        
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {this.state.selected}
          </Dropdown.Toggle>

          <Dropdown.Menu >
          
          {
            this.state.records.map(element =>
            <Dropdown.Item onSelect={this.onSelect.bind(this,element.id)} key={element.id}>{element.name}</Dropdown.Item>
           )  
          }   

        </Dropdown.Menu>
        </Dropdown>


      </div>
    )
  }
}

export default VatDropDown
