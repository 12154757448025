import React, { Component } from 'react'
import axios from 'axios';
import {PieChart} from 'react-minimal-pie-chart';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TimeReport  from './TimeReport';
import moment from 'moment';
import distinctColors from 'distinct-colors'

function mytoFixed(value, precision) {
  var power = Math.pow(10, precision || 0);
  return String(Math.round(value * power) / power);
}

export class X1Report extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
            recordsold1: null,
            tickettotal: null,
            departments: null,
            bydepartment: null,
            from: null,
            to: null,
            chartdata: null,
            totalSales_amt: null,
            totalSales_cnt: null,
            fetchrequested: false,
            lastupdate: null,
            pctup: 0,
            unauth: false
        };
    }

    getSameDayOfYear(date, yearsToAdd){
      date = typeof(date) === "string" ? new Date(date) : date;
      var retDate = new Date(+date);
      retDate.setYear(retDate.getFullYear() + yearsToAdd);
      
      var diff = date.getDay() - retDate.getDay();
      retDate.setDate(retDate.getDate() + diff);
     
      var wk1 = moment(date).isoWeek();
      var wk2 = moment(retDate).isoWeek();
      
      //console.log("Week 1 :"+wk1+" week 2 :"+wk2);

      var wkerr = wk1-wk2;

      retDate=moment(retDate).add(wkerr, 'weeks').toDate();

      //console.log("CONVERT -> "+date.toLocaleDateString() + " to "+retDate.toLocaleDateString()+" diff "+diff)
      return retDate;
    }

    doXHR()
    {

      var today = new Date();
      var lastyear = new Date();
      var dd = today.getDate();
      
      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      var old_yyyy = today.getFullYear()-1;

      var hh = today.getHours();
      var mx = today.getMinutes();
      var sx = today.getSeconds();

      var up = hh+":"+mx+":"+sx;

      this.setState({lastupdate: up});

      if(dd<10) 
      {
          dd='0'+dd;
      } 
      
      if(mm<10) 
      {
          mm='0'+mm;
      } 
      today = yyyy+'-'+mm+'-'+dd;
      lastyear = old_yyyy+'-'+mm+'-'+dd;
  
      var lastyearbydate = this.getSameDayOfYear(today,-1);

      var dd2 = lastyearbydate.getDate();  
      var mm2 = lastyearbydate.getMonth()+1; 
      var yyyy2 = lastyearbydate.getFullYear();
   
      var lastyear2 = yyyy2+'-'+mm2+'-'+dd2;
   

     // console.log("last year is")
     // console.log(lastyear2);

    this.setState({from: today});
    this.setState({to: today});

    console.log("REQUESTING DATA");

    var url = this.props.baseUrl+'Z1?start='+lastyear+'&end='+lastyear;

    var pthis = this;


    axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
    //  console.log("GOT DATA");
    //  console.log(response);
     
      this.setState({recordsold1: response.data.records[response.data.records.length-1].PLU_TTL_value});
     // console.log(response.data.records);

      this.forceUpdate();
    })
    .catch(function (error) {

    if(error.response)
      {
        if (error.response.status === 401)
        {
          console.log("Unauthorized");
          pthis.setState({unauth: true});
        }
      }
    })


    var url2 = this.props.baseUrl+'Z1?start='+lastyear2+'&end='+lastyear2;
    axios.get(url2 , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      console.log(response);
     
      this.setState({recordsold2: response.data.records[response.data.records.length-1].PLU_TTL_value});
     // console.log(response.data.records);

      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })

    axios.get(this.props.baseUrl+'department/' , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      this.setState({departments: response.data.records});
      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })

    axios.get(this.props.baseUrl+'sales?bydpt&start='+today+'&end='+today , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {

      this.setState({bydepartment: response.data.records});

      if(this.state.bydepartment!== null)
      {
        var chartdata =[];

        var totalSales_amt=0;
        var totalSales_cnt=0;
        this.state.bydepartment.forEach(function(element){  
          totalSales_amt += Number(element.total);
          totalSales_cnt += Number(element.count);
        });
   
        var palette = distinctColors({count: this.state.bydepartment.length}) // You may pass an optional config object

        var n=0;
        this.state.bydepartment.forEach(function(element){ 
          var pct = 100.0*(Number(element.total) / totalSales_amt);

          var colorstring = "#"+Math.round(palette[n]._rgb[0]).toString(16) + Math.round(palette[n]._rgb[1]).toString(16) + Math.round(palette[n]._rgb[2]).toString(16);

          chartdata.push({ title: element.name, value: pct, color: colorstring, total: element.total }) 
          n++;

        });


        chartdata.sort((b,a) => a.value - b.value);
  
        this.setState({chartdata: chartdata});
        this.setState({totalSales_amt: totalSales_amt});
        this.setState({totalSales_cnt: totalSales_cnt});
        


        this.interval = setInterval(() => this.refresh(), 1000);

    }

    this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })


    axios.get(this.props.baseUrl+'tickettotal/?date='+today+"&to="+today+"/" , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
    this.setState({tickettotal: response.data.records[0].total});
    this.forceUpdate();
    })
    .catch(function (error) {
    console.log(error);
    })


    }


    componentDidMount()
    {

      try {
      
      setInterval(async () => {
        this.doXHR();
    
      }, 1000*60);
    } catch(e) {
      console.log(e);
    }

    this.doXHR();

    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

  refresh()
  {
    this.forceUpdate();
  }

  render() {

 

    if( this.state.departments === null || this.state.chartdata === null)
        return(
            <div> 
            <h2>Generating your report</h2>
            <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
            </div>
        );

        
    var key=0;



return (
      <div>

    <h1>X Report</h1>
    <p>Date: {this.state.from} {this.state.lastupdate}</p>
    
    <p>Total Takings = £{this.state.tickettotal} </p>
    Total sales £{mytoFixed(this.state.totalSales_amt,2)} ({this.state.totalSales_cnt}) <br/>

    Last year (by day) sales £{mytoFixed(this.state.recordsold2,2)}<br/>
    Difference {mytoFixed(-100+100*this.state.tickettotal/this.state.recordsold2,2)} % <br/>



    <PieChart 
      style={{height: '300px'}}
      label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
      labelStyle={{
        fontSize: '5px',
        fontFamily: 'sans-serif'
      }}
      radius={42}
      labelPosition={112}
      data={this.state.chartdata}     
    />;

    <ul style={{listStyleType: 'none'}}>
      {
       
        this.state.chartdata.map(element =>
          <li key={key++} style={{listStyle: 'none'}}> <div style={{display: 'block', float: 'left', width: '20px', height: '20px', backgroundColor: element.color, marginRight: '10px' }}></div>{mytoFixed(element.value,2)}{'%  £'}{mytoFixed(element.total,2)} {element.title}</li>
          )
      }
    </ul>


      <TimeReport {...this.props}  baseUrl={this.props.baseUrl} jwt={this.props.jwt} />

      
      </div>
    )
  }
}

X1Report.propTypes = {
  jwt: PropTypes.string.isRequired
};

export default withRouter(X1Report)
