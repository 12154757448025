

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';


//import enGb from 'react-date-range/dist/locale/en-GB';
import enGb from 'date-fns/esm/locale/en-GB';

import '../App.css'; // main style file
import 'bootstrap/dist/css/bootstrap.min.css';

import DateRangeSelector from "../components/DateRangeSelector";

import {Button,ButtonToolbar} from 'react-bootstrap';

class MyDatePicker extends Component {
    constructor(props, context) {
      super(props);
    
        this.state = {
          dateRange: {
            selection: {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection',
            },
          },
        }
    }

    handleRangeChange(which, payload) {
        this.setState({
          [which]: {
            ...this.state[which],
            ...payload,
          },
        });
      }

	render(){


    moment.locale("GB");

    const startId = uuidv4();
    const endId = uuidv4();


		return (
            <div style={{float: 'none', marginLeft: '20px', marginRight: 'auto',display: 'block'}}>
            
          <DateRangeSelector />;

       


         </div>

		)
    }
    
    handlebutton(which,button)
    {
        
        if(which==="accept")
        {
        
            var fromdate = this.state.dateRange.selection.startDate.getFullYear()+"-"+(this.state.dateRange.selection.startDate.getMonth()+1)+"-"+this.state.dateRange.selection.startDate.getDate()
            var todate = this.state.dateRange.selection.endDate.getFullYear()+"-"+(this.state.dateRange.selection.endDate.getMonth()+1)+"-"+this.state.dateRange.selection.endDate.getDate()

            this.props.history.push(this.props.newtarget+"?from="+fromdate+"&to="+todate);
        }
        if(which==="Cancel")
        {
          
            this.props.history.push('/')
        }
    }
}

export default withRouter(MyDatePicker);