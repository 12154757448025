import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Quagga from 'quagga';

export class Scanner extends Component {

    constructor(props) {
        super(props);

        this._onDetected = this._onDetected.bind(this);  

        this.scancount = 0;
        
        this.state = {
            lastresult: 0,
            samecount: 0 
        };
    }


    render() {
        return (
            <div id="interactive" className="viewport"/>
        );
    }

    componentDidMount() {
        Quagga.init({
            inputStream: {
                type : "LiveStream",
                constraints: {
                    width: 640,
                    height: 480,
                    facing: "environment", // or user
                    aspectRatio: 2,
                },
                singleChannel: false,
            },
            locator: {
                patchSize: "medium",
                halfSample: true
            },
            numOfWorkers: 1,
            frequency: 10,
            decoder: {
                readers : [ "ean_reader"]
            },
          
            locate: true,
            multiple: false,
        }, function(err) {
            if (err) {
                return console.log(err);
            }
            Quagga.start();
        });
        Quagga.onDetected(this._onDetected);
    }

    componentWillUnmount() {
        Quagga.offDetected(this._onDetected);
    }

    
    _onDetected(result) {

        var drawingCtx = Quagga.canvas.ctx.overlay, drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
            result.boxes.filter(function (box) {
              return box !== result.box;
            }).forEach(function (box) {
              Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
            });
          }
    
          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
          }
    
          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
          }
        }


        if(this.state.lastresult === result.codeResult.code)
        {
            this.scancount = this.scancount +1;
        }
        else
        {
            this.scancount = 0;
        }

        this.setState({lastresult: result.codeResult.code});

        if(this.scancount===3)
        {
            this.props.onDetected(result);
        }
    }
};

Scanner.propTypes = {
    onDetected: PropTypes.func.isRequired
};

export default Scanner
