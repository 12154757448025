import React, { Component } from 'react'
import axios from 'axios';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import BootstrapTable  from 'react-bootstrap-table-next';
import {Button} from 'react-bootstrap';
import { ResponsiveContainer, YAxis,Legend,BarChart, Bar, ScatterChart,Scatter,CartesianGrid,XAxis,Tooltip} from 'recharts';
import moment from 'moment';


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
 
   //console.log(payload);
   //console.log(label);
 
     return (
 
       <div className="custom-tooltip">
         <p className="label">{`${moment(payload[0].value).format('dd/MM/yyyy')} : £${payload[1].value} `}</p>
       </div>
     );
   }
 
   return null;
 };

export class ItemReport extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            sku: null,
            records: null,
            from: null,
            to: null,
            title: null,
            subtitle: null,
            totalSales_amt: 0,
            totalSales_cnt: 0,
            graphdata: null,
        }
    }

  componentDidMount()
  {
    console.log("ITEM REPORT "+this);

    const searchvalues = queryString.parse(this.props.location.search)
    this.setState({from: searchvalues.from});
    this.setState({to: searchvalues.to});
    this.setState({sku: searchvalues.sku})

    var url = this.props.baseUrl+"products/"+searchvalues.sku+"/sales/";

    this.setState({title: 'Item Sales Report'});

    axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      console.log(response);
      this.setState({records: response.data.records});
      
      if(response.data.count!=="0")
      {
         this.setState({subtitle: "Stock name: " +response.data.records[0].stock_name});
      }
      
      var totalSales_amt=0;
      var totalSales_cnt=0;

      console.log(response.data.records);

      var xydata = [];
      var total=0;

      response.data.records.forEach(function(element){  
        console.log("fick");
        totalSales_amt += Number(element.price);
        totalSales_cnt += Number(element.delta);

        
        total = total + parseInt(element.delta) ;

        
        xydata.push({Date: new Date(element.date), Sold: total})
        

      });
      this.setState({graphdata: xydata});

      console.log("XY DATA is ...");
      console.log(xydata);

      this.setState({totalSales_amt: totalSales_amt.toFixed(2)});
      this.setState({totalSales_cnt: totalSales_cnt});

      this.forceUpdate();


    })
    .catch(function (error) {
      console.log(error);
    })
  }

  getxychart()
  {

      if(this.state.graphdata===null)
      {
        return(<div></div>);
      }
      return(
        <div>

        <ResponsiveContainer width = '95%' height = {400} >
  
         <ScatterChart
          margin={{
            top: 20, right: 20, bottom: 20, left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="Date" name="Date" unit=""  domain = {['auto', 'auto']}
          tickFormatter = {(unixTime) => moment(unixTime).format('MM/yyyy')}
          type = 'Date' />
          <YAxis type="number" dataKey="Sold" name="Sold" unit="" />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />}  />
          <Legend />
          <Scatter name="Sold History" data={this.state.graphdata} fill="#8884d8" line shape="cross" />
        </ScatterChart>
        </ResponsiveContainer>
        
         
          </div>     
      );
  }

  render() {

    if( this.state.records === null)
    return(
        <div> 
        <h2>Generating your report</h2>
        <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
        </div>
    );

    const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{this.state.title}</h3>;
 
    const columns = [{
          dataField: 'id',
          text: 'id',
          hidden: true
      }, {
        dataField: 'date',
        text: 'Date',
        sort: 'true'
      }, {
        dataField: 'delta',
        text: 'Delta',
        sort: 'true'
      },{
        dataField: 'price',
        text: 'Price'
      }];

     return (
       <div>
        <CaptionElement />

        <p>{this.state.subtitle}<br />
        From: {this.state.from}<br />
        To: {this.state.from} <br />
        Total amount: £{this.state.totalSales_amt} <br />
        Total count: {this.state.totalSales_cnt} 

        </p>

        <Button className="button2" variant="danger" block onClick={this.copytoclip.bind(this)}>Copy</Button>

        <div className='AA'>
          <div className='AB'>

              <BootstrapTable
                keyField="id"
                data={this.state.records}
                columns={ columns }
                striped
                />
          </div>
          <div className='AC'>
            {this.getxychart()}
          </div>
        </div>

       </div>
     )

  }

  copytoclip()
  {
  
    console.log('thetable'+this.props.dpt);

    const elTable = document.getElementById('thetable'+this.props.dpt);
  
  let range, sel;
  
  // Ensure that range and selection are supported by the browsers
  if (document.createRange && window.getSelection) {
  
    range = document.createRange();
    sel = window.getSelection();
    // unselect any element in the page
    sel.removeAllRanges();
  
    try {
      range.selectNodeContents(elTable);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(elTable);
      sel.addRange(range);
    }
  
    document.execCommand('copy');
  }

}

}

export default ItemReport
