import React, { Component } from 'react';
import {BrowserRouter as Router,Route,Switch } from 'react-router-dom';

import MainMenu from './components/Pages/MainMenu';
import ReportsMenu from './components/Pages/ReportsMenu';
import Actions from './components/Pages/Actions';
import GetSales from './components/Pages/GetSales';
import ScannerControl from './components/quagga/scannercontrol';
import ScannerControl2 from './components/scannercontrol2';


import './App.css';
import DateSelWrapper from './components/DateSelWrapper';
import GetTakings from './components/Pages/GetTakings';
import EditItem from './components/Pages/EditItem';
import { MenuHeader } from './components/MenuHeader';
import Login from './components/Login';
import ItemReport from './components/Pages/ItemReport';
import ManualEntry from './components/Pages/ManualEntry';
import GetTickets from './components/Pages/GetTickets';
import { X1Report } from './components/Pages/X1Report';
import { TimeReport } from './components/Pages/TimeReport';
import StockMPN from './components/Pages/StockMPN';

import StockSales from './components/Pages/StockSales';


import ItemSearch from './components/Pages/ItemSearch';

import Top100Value from './components/Pages/Top100Value';

import BookIn from './components/Pages/BookIn';
import BookInPre from './components/Pages/BookInPre';

import WriteOff from './components/Pages/Writeoff';


import ShowSuppliers from './components/Pages/Suppliers/ShowSuppliers';
import EditAddSupplier from './components/Pages/Suppliers/EditAddSupplier';

import ShowECRUsers from './components/Pages/ECRUsers/ShowECRUsers'
import EditAddECRUser from './components/Pages/ECRUsers/EditAddECRUser'


import ShowUsers from './components/Pages/Users/ShowUsers'
import EditAddUser from './components/Pages/Users/EditAddUser'

import ResetPassword from './components/ResetPassword';
import ResetPassword2 from './components/ResetPassword2';

import BookInReport from './components/Pages/BookInReport'


class App extends Component {
  constructor(props) {
    super(props);

   

    var url = new URL(window.location.href);
   
    if(url.hostname==="shop.byteme.org.uk")
    {
      console.log("*** PRODUCTION SERVER ****");
 
      this.state = {
        baseUrl: "https://shop.byteme.org.uk/crudoff/2.0/", 
        baseUrl2: "https://shop.byteme.org.uk/",
        jwt: null,
        warntitle: ''
      };
    }
    else if(url.hostname==="localhost")
    {
      console.log("*** LIVE SYSTEM SERVER ****");
 
      this.state = {
        baseUrl: "https://shoptest.byteme.org.uk/crudoff/2.0/",
        baseUrl2: "https://shoptest.byteme.org.uk/",
        jwt: null,
        warntitle: ''
      };
    }
    else
    {
      console.log("*** THE DEVELOPMENT SERVER ****");
      this.state = {
          baseUrl: "https://shoptest.byteme.org.uk/crudoff/2.0/", 
          baseUrl2: "https://shoptest.byteme.org.uk/",
          jwt: null,
          warntitle: ''
        };
    } 

    console.log("Base URL is"+this.state.baseUrl);
}

componentDidMount() 
{


  var jwt = this.getCookie("auth");
  var username = this.getCookie("username");
  var authlevel = this.getCookie("authlevel");
  

  //console.log("Setting JWT COOKIE to "+jwt);
  this.setState({jwt: jwt});
  this.setState({username: username});
  this.setState({authlevel: authlevel});
  


}

getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

clearCookie(cookieName) {
  console.log("Clearing cookie "+cookieName);
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

isTokenExpired(token) {
  if(!token)
    return true;

  const decodedToken = JSON.parse(atob(token.split('.')[1])); // decode token
  const expirationTime = decodedToken.exp * 1000; // convert to milliseconds
  const currentTime = Date.now();
  return expirationTime < currentTime;
}


  updatejwt = (jwt,username,authlevel) =>
  {
    //console.log("Setting jwt in app state to"+jwt);
    //console.log(username);
    //console.log(authlevel);
    
    this.setState({jwt: jwt});
    this.setState({username: username});
    this.setState({authlevel: authlevel});
    

    if(jwt === null)
    {
      this.clearCookie("auth");
      this.clearCookie("username");
      this.clearCookie("authlevel"); 
    }

  }

  render() {

    //console.log("User "+this.state.username);
    //console.log("Level "+this.state.authlevel);

    /*
    if(!this.state.jwt || this.state.jwt.length<10)
    //if(jwt === null || jwt.length<10)
    { 
      return (
        <div>
        <Login parent={this} baseUrl={this.state.baseUrl} updatejwt={this.updatejwt}/>
        </div>
      )
    }
*/

    let login;
    let route;
    if(!this.state.jwt || this.state.jwt.length<10 || this.isTokenExpired(this.state.jwt))
    {
      login =
      <div>
      <Switch>
      <Route path="/ResetPassword" render={props => <ResetPassword {...props} baseUrl={this.state.baseUrl} baseUrl2={this.state.baseUrl2} jwt={this.state.jwt} />} />
      <Route path="/DoResetPassword" render={props => <ResetPassword2 {...props} baseUrl={this.state.baseUrl} baseUrl2={this.state.baseUrl2} jwt={this.state.jwt} />} />
  
      <Route render={props => <Login {...props} parent={this} baseUrl={this.state.baseUrl} updatejwt={this.updatejwt}/>} />   
      </Switch>
      </div>

    }
    else
    {
    route = <div>
     <Route render={ props=><MenuHeader {...props} user={this.state.username}  /> }   />
          <div className="BodyNav">
         
          <Switch>

          <Route path="/ReportsMenu" render={props => <ReportsMenu {...props}  baseUrl={this.state.baseUrl} jwt={this.state.jwt} authlevel={this.state.authlevel}  />} />
          <Route path="/Actions" render={props =><Actions {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} authlevel={this.state.authlevel} /> } />

          <Route path="/TakingsRptZ1" render={props =><DateSelWrapper> <GetTakings {...props} baseUrl={this.state.baseUrl} type={'Z1'}  jwt={this.state.jwt} /> </DateSelWrapper>} />
          <Route path="/TakingsRptZ2" render={props =><DateSelWrapper> <GetTakings {...props} baseUrl={this.state.baseUrl} type={'Z2'}  jwt={this.state.jwt} /> </DateSelWrapper>} />         
        
          <Route path="/GetSales" render={props => <DateSelWrapper> <GetSales {...props}  baseUrl={this.state.baseUrl} jwt={this.state.jwt} /> </DateSelWrapper>} />
 

 

          <Route path="/X1Report" render={props => <X1Report  {...props}  baseUrl={this.state.baseUrl} jwt={this.state.jwt} /> } />

          <Route path="/TimeReport" render={props => <DateSelWrapper> <TimeReport {...props}  baseUrl={this.state.baseUrl} jwt={this.state.jwt} /> </DateSelWrapper>} />

         
          <Route path="/GetTickets" render={props => <DateSelWrapper> <GetTickets {...props}  baseUrl={this.state.baseUrl} jwt={this.state.jwt} /> </DateSelWrapper>} />
          
          <Route path="/EditItem" render={props => <EditItem {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />

          <Route path="/BookIn" render={props => <BookIn {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />

          <Route path="/BookInPre" render={props => <BookInPre {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />

          <Route path="/WriteOff" render={props => <WriteOff {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />


          <Route path="/ItemReport" render={props => <ItemReport {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
          
          <Route path="/Scanner" component={ScannerControl} />
          <Route path="/Scanner2" component={ScannerControl2} />
             
          <Route path="/ManualEntry" component={ManualEntry} />   
 
          <Route path="/StockMPN" render={props => <StockMPN {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
     

          <Route path="/StockSalesAll2" render={props => <StockSales {...props} v='0' baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
          <Route path="/StockSalesBetween" render={props => <DateSelWrapper> <StockSales {...props} v='1' baseUrl={this.state.baseUrl} jwt={this.state.jwt} /> </DateSelWrapper>} />
          <Route path="/StockSalesAll" render={props =><Top100Value {...props} v='4' baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
          <Route path="/BookInHistory" render={props =><BookInReport {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
             

          <Route path="/ItemSearch" render={props => <ItemSearch {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
 
          <Route path="/Suppliers/ShowAll" render={props => <ShowSuppliers {...props} authlevel={this.state.authlevel}  baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
          <Route path="/Suppliers/AddEdit" render={props => <EditAddSupplier {...props} authlevel={this.state.authlevel} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />

          <Route path="/ECRUsers/ShowAll" render={props => <ShowECRUsers {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
          <Route path="/ECRUsers/AddEdit" render={props => <EditAddECRUser {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />

          <Route path="/Users/ShowAll" render={props => <ShowUsers {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />
          <Route path="/Users/AddEdit" render={props => <EditAddUser {...props} baseUrl={this.state.baseUrl} jwt={this.state.jwt} />} />

  
          <Route  render={props => <MainMenu {...props} updatejwt={this.updatejwt} /> }  />  
    
          </Switch>
         

        

          </div>                           
         

    </div>
    }



    return (    
     
      <div className="App">
        
        {/*<h1>{this.state.warntitle}</h1>*/}

        <Router>

        {login}

        {route}

       

          
        </Router>
      
             
      </div>
     );
  }


}


export default (App);
