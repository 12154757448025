import React, { Component } from 'react'
import axios from 'axios';
import { ResponsiveContainer, YAxis,Legend,BarChart, Bar, ScatterChart,Scatter,CartesianGrid,XAxis,Tooltip} from 'recharts'
import queryString from 'query-string';
import moment from 'moment';
import {PieChart} from 'react-minimal-pie-chart';
import distinctColors from 'distinct-colors'

const CustomTooltip = ({ active, payload, label }) => {
   if (active) {
  
    //console.log(payload);
    //console.log(label);
  
      return (
  
        <div className="custom-tooltip">
          <p className="label">{`${moment(payload[0].value).format('HH:mm')} : £${payload[1].value} `}</p>
        </div>
      );
    }
  
    return null;
  };


  function mytoFixed(value, precision) {
    var power = Math.pow(10, precision || 0);
    return String(Math.round(value * power) / power);
  }

export class TimeReport extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            results: null,
            data: null,
            histogram: null,
            histogramdata: null,
            from: null,
            to: null,
            total: null,
            clerkamt: null,
            
        }    
    }

  

    getSameDayOfYear(date, yearsToAdd){
      date = typeof(date) === "string" ? new Date(date) : date;
      var retDate = new Date(+date);
      retDate.setYear(retDate.getFullYear() + yearsToAdd);
      
      var diff = date.getDay() - retDate.getDay();
      retDate.setDate(retDate.getDate() + diff);
     
      var wk1 = moment(date).isoWeek();
      var wk2 = moment(retDate).isoWeek();
      
      //console.log("Week 1 :"+wk1+" week 2 :"+wk2);

      var wkerr = wk1-wk2;

      retDate=moment(retDate).add(wkerr, 'weeks').toDate();

      //console.log("CONVERT -> "+date.toLocaleDateString() + " to "+retDate.toLocaleDateString()+" diff "+diff)
      return retDate;
    }

    componentDidMount()
    {

        const searchvalues = queryString.parse(this.props.location.search)     
        var size = Object.keys(searchvalues).length;

        if(size>0)
        {
          console.log("GOT SEARCH VALUES");
         
        }
        else
        {
          console.log("DEFAULT SEARCH VALUES");
          const date = new Date();

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          // This arrangement can be altered based on how we want the date's format to appear.
          let currentDate = `${year}-${month}-${day}`;
    

          searchvalues['from'] = currentDate;
          searchvalues['to'] = currentDate;
          
        }

        this.setState({from: searchvalues.from});
        this.setState({to: searchvalues.to});

        if(searchvalues.from!==searchvalues.to)
        {
            this.setState({displayxy: 0});
        }
        else
        {
          this.setState({displayxy: 1});
        }

        
        axios.get(this.props.baseUrl+'ticket/?start='+searchvalues.from+'&end='+searchvalues.to , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
    
          this.setState({results: response.data.records});
    
          if(response.data.results !== null){
            var totalSales_amt=0;

            var data = [];
            var clerkcount = [];
            var clerkamt = [];
            

            response.data.records.forEach(function(element){  
                totalSales_amt += Number(element.ticketTotal);               
            });

            this.setState({total: totalSales_amt});
                                       
            var histogram = {
                        8 : 0,
                        9 : 0,
                        10 : 0,
                        11 : 0,
                        12 : 0,
                        13 : 0,
                        14 : 0,
                        15 : 0,
                        16 : 0,
                        17 : 0,
                        18 : 0,
                        19 : 0,
                        20 : 0,
                        21 : 0,
                        22 : 0
                      };
                    
            var pct=0;
            var totalcount=0;
            response.data.records.forEach(function(element){
                 var amt = 1.0* (element.ticketTotal);
           
                 pct = pct + amt;
                 var d = new Date(element.dt);
                 data.push({Time: d.getTime(), Takings: pct})

                 if(clerkcount[element.clark] === undefined)
                  clerkcount[element.clark] = 0;

                 clerkcount[element.clark] += 1;
                 totalcount++;

                 //date format from server is 2020-01-11 12:26:22.000000
                
                 var xx = moment(element.dt);
                 var dt = new Date(xx);
                
                 //console.log ("DATEPARSE "+xx+" is "+dt);
                 histogram[dt.getHours()] = parseFloat(histogram[dt.getHours()]) + parseFloat(element.ticketTotal);                  
                 //console.log("ENTRY "+dt.getHours()+" is "+parseFloat(element.ticketTotal));

            });

            var clerkamt2 = [];

            var palette = distinctColors({count: Object.keys(clerkcount).length}) // You may pass an optional config object

            var n=0;
            for (const [key, value] of Object.entries(clerkcount)) {
              console.log(`${key}: ${value}`);
              var total = value;
              var pct = 100* (value/totalcount);

              var colorstring = "#"+Math.round(palette[n]._rgb[0]).toString(16) + Math.round(palette[n]._rgb[1]).toString(16) + Math.round(palette[n]._rgb[2]).toString(16);

              clerkamt2.push({title: key, value: pct, total: total, color: colorstring})
              n++;

            }

           
            this.setState({clerkamt: clerkamt2});

            console.log(clerkamt2);

           
            var hd = [];

            for(var i=8;i<23;i++)
            {
                hd.push( {name: i, amt: histogram[i], amt2: histogram[i],});
            }

            //console.log(hd);

            //console.log(data);

            this.setState({histogramdata: hd});          
            this.setState({data: data});           
            //this.setState({histogram: histogram});
            //this.forceUpdate();
          }

        });

        ///if(searchvalues.from === searchvalues.to)
        {

          console.log("Search values from :"+searchvalues.from);

          var lastyearbydate = this.getSameDayOfYear(searchvalues.from,-1);
          var thisyearbydate = this.getSameDayOfYear(searchvalues.from,0);
          
          var dd2 = lastyearbydate.getDate();  
          var mm2 = lastyearbydate.getMonth()+1; 
          var yyyy2 = lastyearbydate.getFullYear();
   
          var ly = yyyy2+'-'+mm2+'-'+dd2;

        //  console.log("Last yeat is "+ly);

          axios.get(this.props.baseUrl+'ticket/?start='+ly+'&end='+ly , {headers: { Authorization: `Bearer `+this.props.jwt }})
          .then(response => {

          //  console.log("&&&& RESPONSE LAST YEAR");
      
            this.setState({results2: response.data.records});
      
            if(response.data.results !== null){
              var totalSales_amt=0;
  
              var data = [];
  
              response.data.records.forEach(function(element){  
                  totalSales_amt += Number(element.ticketTotal);               
              });
  
              this.setState({total2: totalSales_amt});
                                         
              var histogram = {
                          8 : 0,
                          9 : 0,
                          10 : 0,
                          11 : 0,
                          12 : 0,
                          13 : 0,
                          14 : 0,
                          15 : 0,
                          16 : 0,
                          17 : 0,
                          18 : 0,
                          19 : 0,
                          20 : 0,
                          21 : 0,
                          22 : 0
                        };
                      
              var pct=0;
              response.data.records.forEach(function(element){
                   var amt = 1.0* (element.ticketTotal);
             
                   pct = pct + amt;
                   var d = new Date(element.dt);

                 
 
                  var h = d.getHours();
                  var m = d.getMinutes();
                  var s = d.getSeconds();

                  var hms = h+":"+m+":"+s;

                 // console.log(hms);

                 
                   var dd3 = thisyearbydate.getDate();  
                   var mm3 = thisyearbydate.getMonth()+1; 
                   var yyyy3 = thisyearbydate.getFullYear();

                   var xxx = moment(yyyy3+"-"+mm3+"-"+dd3+" "+h+":"+m+":"+s);
                   var dt = new Date(xxx);

                   //data.push({Time: d.getTime(), Takings: pct})
                  data.push({Time: dt.getTime(), Takings: pct})
  
                   //date format from server is 2020-01-11 12:26:22.000000
                  
                   var xx = moment(element.dt);
                   var dt = new Date(xx);
                  
                   //console.log ("DATEPARSE "+xx+" is "+dt);
                   histogram[dt.getHours()] = parseFloat(histogram[dt.getHours()]) + parseFloat(element.ticketTotal);                  
                   //console.log("ENTRY "+dt.getHours()+" is "+parseFloat(element.ticketTotal));
  
              });
  
             
              var hd = [];
  
              for(var i=8;i<23;i++)
              {
                  hd.push( {name: i, amt: histogram[i], amt2: histogram[i],});
              }
  
              //console.log(hd);

              console.log(data);


              this.setState({histogramdata2: hd});          
              this.setState({data2: data});           
              //this.setState({histogram: histogram});
              this.forceUpdate();
            }
  
          });
  
  
  
        }
  


    }

    getxychart()
    {

        if(this.state.displayxy!==1)
        {
          return(<div></div>);
        }
        return(
          <div>

          <ResponsiveContainer width = '95%' height = {400} >
    
           <ScatterChart
            margin={{
              top: 20, right: 20, bottom: 20, left: 20,
            }}
          >
            <CartesianGrid />
            
            <XAxis dataKey="Time" name="Time" unit=""  domain = {['auto', 'auto']}
            tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm')}
            type = 'number' />
            <YAxis type="number" dataKey="Takings" name="Takings" unit="£" />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />}  />
            <Legend />
            <Scatter name="Last year" data={this.state.data2} fill="#ff00ff" line shape="cross" />
         
            <Scatter name="Takings" data={this.state.data} fill="#00ffff" line shape="cross" />
            
          </ScatterChart>
          </ResponsiveContainer>
          
           
            </div>     
        );
    }


    render() {

      var key=0;

   
        if(this.state.histogramdata===null)
        {
          return "RENDERING";
        }
        
        return (
            <div>

<div>
    <h1>Time Report</h1>
    <p>From: {this.state.from} <br/>
    To: {this.state.to} <br/>
    Total: {this.state.total} <br/>
    </p>

    <PieChart 
      style={{height: '300px'}}
      label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
      labelStyle={{
        fontSize: '5px',
        fontFamily: 'sans-serif'
      }}
      radius={42}
      labelPosition={112}
      data={this.state.clerkamt}     
    />;

    <ul style={{listStyleType: 'none'}}>
      {
       
        this.state.clerkamt.map(element =>
          <li key={key++} style={{listStyle: 'none'}}> <div style={{display: 'block', float: 'left', width: '20px', height: '20px', backgroundColor: element.color, marginRight: '10px' }}></div>{mytoFixed(element.value,2)}{'% '}{mytoFixed(element.total,2)} {element.title}</li>
          )
      }
    </ul>
   
    <ResponsiveContainer width = '95%' height = {400} >
    <BarChart
        isAnimationActive={false}
        width={350}
        height={300}
        data={this.state.histogramdata}
        margin={{
          top: 5, right: 5, left: 5, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="amt" fill="#8884d8" />
      </BarChart>
      </ResponsiveContainer>
      </div>
     
     {this.getxychart()}
        </div>
        )
    }
}

export default TimeReport
