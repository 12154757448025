import React, { Component } from 'react'
import axios from 'axios';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import BootstrapTable  from 'react-bootstrap-table-next';
import {Button} from 'react-bootstrap';
import MagicTable from "../Functional/magictable";

import {
  textFilter,
  selectFilter,
  dateFilter,
  numberFilter,
  Comparator
} from "react-bootstrap-table2-filter";

const selectBReason =[{ value: "Book In", label: "Book In"},{value: "Stock Take", label: "Stock Take"},{value: "Write Off" , label: "Write Off"}];
const selectWReason = [{ value: "Broken", label: "Broken"},{value: "Stolen", label: "Stolen"}];

export class StockMPN extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            sku: null,
            records: null,
            records2: null,
            
          
            
        }
    }

  componentDidMount()
  {
    console.log(this);

    const searchvalues = queryString.parse(this.props.location.search)
    this.setState({sku: searchvalues.sku})

    var url = this.props.baseUrl+"products/"+searchvalues.sku+"/";

    this.setState({title: 'Item Sales Report'});

    axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      this.setState({records: response.data.records}); 
    })
    .catch(function (error) {
      console.log(error);
    })

    var url2 = this.props.baseUrl+"products/history/"+searchvalues.sku;
    axios.get(url2 , {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response2 => {
      this.setState({records2: response2.data.records}); 
    })
    .catch(function (error) {
      console.log(error);
    })


  }

  render() {

    if( this.state.records === null || this.state.records2 === null)
    return(
        <div> 
        <h2>Generating your report</h2>
        <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
        </div>
    );

    const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{this.state.records[0].stock_name}</h3>;


    if(this.state.records.length === 0)
    {
      return(
      <div>
    
      <p>
        No results returned .. not on database?
      </p>
      </div>
      )
    }


    const columns = [{
      dataField: 'id',
      text: 'id',
      hidden: true
      }, {
        dataField: 'date',
        text: 'Date',
        sort: true,
        filter: dateFilter(),
        sort: true
      }, {
        dataField: 'sreason',
        text: 'Reason',
        sort: true,
        filter: selectFilter({
          options: selectBReason,
        }),
      },
      {
        dataField: 'wreason',
        text: 'Write off',
        sort: true,
        filter: selectFilter({
          options: selectWReason,
        }),
      },
      {
        dataField: 'stock_update',
        text: 'Stock Update',
        sort: true
      },{
        dataField: 'stock_level',
        text: 'Pre stock level',

        sort: true
      }
      ,{
        dataField: 'invoiceid',
        text: 'Invoice',
        sort: true,
        filter: textFilter(),
        sort: true
      }
      ,
      {
        dataField: 'orderid',
        text: 'Order',
        sort: true,
        filter: textFilter(),
        sort: true
      }
    
    
    ];

     
     return (
       <div>
        <CaptionElement />
        <Button className="button2" variant="danger" block onClick={this.copytoclip.bind(this)}>Copy</Button>
        <p>
        Stock name: {this.state.records[0].stock_name}<br />
        Barcode: {this.state.records[0].barcode}<br />
     
        Stock qty: {this.state.records[0].stock_quatity}<br />
        Selling price: {this.state.records[0].selling_price}<br />
        Cost price: {this.state.records[0].company_price}<br />
       
        </p>

        <MagicTable
          {...this.props}
          data={this.state.records2}
          columns={columns}
        />

     

       </div>
     )

  }

  copytoclip()
  {
  
    console.log('thetable'+this.props.dpt);

    const elTable = document.getElementById('thetable'+this.props.dpt);
  
  let range, sel;
  
  // Ensure that range and selection are supported by the browsers
  if (document.createRange && window.getSelection) {
  
    range = document.createRange();
    sel = window.getSelection();
    // unselect any element in the page
    sel.removeAllRanges();
  
    try {
      range.selectNodeContents(elTable);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(elTable);
      sel.addRange(range);
    }
  
    document.execCommand('copy');
  }

}

}

export default StockMPN
