import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import {Button,Form} from 'react-bootstrap';

import queryString from 'query-string';
import axios from 'axios';
import ReactLoading from 'react-loading';
import Modalmsg from '../modalmsg';
import Okmsg from '../Okmsg';
import DropDownConstant from '../DropDownConstant'


 export class WriteOff extends Component {
  constructor(props) {
    super(props);

    this.state = {
        sku: null,
        records: null,
        name: '',      
        qty: 0,  
        hasErrors: false,
        formErrors_stock: '',  
        formErrors_reason: '',  
        msg: null,
        Title: null,
        ok: false,
        newitem: false, 
        reason: 0,      
    }
}

componentDidMount()
 {

  console.log("***");
  console.log(this);

  const searchvalues = queryString.parse(this.props.location.search);
  this.setState({sku: searchvalues.sku});

  var url = this.props.baseUrl+"products/"+searchvalues.sku+"/";

  axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt}})
  .then(response => {
 
    console.log("Edit item response");
    console.log(response);

    if(response.data['Status']==="OK" && response.data['Count'] === 0)
    {
      console.log("NEW ITEM");
      this.setState({newitem: true, brandid: '0', mpn: '',vat: "1.00", records: true})

      this.props.history.push("EditItem?sku="+this.state.sku);
      //new item
    }
    else
    {
        console.log("EXISTING ITEM");
 
        this.setState({name: response.data.records[0].stock_name});         
        this.setState({existingqty: response.data.records[0].stock_quatity});
        this.setState({records: response.data.records});
    }

    this.forceUpdate();

  })
  .catch(function (error) {
    console.log(error);
  })

 }
  
   onChangeQty(e)
   {
      this.setState({qty: e.target.value});
   }

   onChangeReason(e)
   {
      this.setState({reason: e});
   }


   handleSubmit()
   {
  
    this.setState({hasErrors: false});
    this.setState({formErrors_stock: ''});
    this.setState({formErrors_reason: ''});

    var hasErrors = false;

    if(this.state.qty==="" || this.state.qty===null)
    {
      this.setState({formErrors_stock: 'Must not be empty'});
      hasErrors = true;
    }

    if(this.state.reason === 0)
    {
      this.setState({formErrors_reason: 'Must not be empty'});
      hasErrors = true;
    }

    if(isNaN(this.state.qty))
    {
      this.setState({formErrors_stock: 'Not a number'});
      hasErrors = true;   
    }

    if(this.state.qty === 0 )
    {
      this.setState({formErrors_stock: 'Must not be 0'});
      hasErrors = true;   
    }

    if(this.state.qty < 0 )
    {
      this.setState({formErrors_stock: 'Must not be less than 0'});
      hasErrors = true;   
    }

    this.setState({hasErrors: hasErrors});

    if(hasErrors === true)
    {
      console.log("HAS ERRORS!");
      console.log(this.state);
      return;
    }
 
  var url;
  var method;


  url = this.props.baseUrl+"products/writedown/"+this.state.sku;
  method='patch';


  axios({
    method: method,
    url: url,
    data: {
      stock_quatity: this.state.qty,
      writeoffreason: this.state.reason,
    },
    headers:
    {
      Authorization: `Bearer `+this.props.jwt,
    }
  }).then(response => {

    console.log("SUBMIT RESPOSE");
    console.log(response.data);

    if(response.data['error_info'][0] !== '00000')
    {
      this.setState({Title: response.data['error_info'][2],msg: 'Error'});
    }
    else
    {
      this.setState({ok: true});
    }

  })
  .catch(function (error) {
    console.log("SUBMIT ERROR");

    console.log(error);
  })
   }

   
  tick()
  {
    this.props.history.goBack();

  }

   errorClass(error) {    
     
    if(!error)
      return '';

    return(error.length === 0 ? '' : 'has-error');
 }


  render() {

    if(this.state.ok===true)
    {
      return(
          <div>
            <Okmsg />
          </div>
      )
    }

    if(this.state.msg !=  null)
    {
        return(
            <div>
            <Modalmsg ret={this.onModalReturn.bind(this)} Title={this.state.msg} msg={this.state.Title}  />
            </div>
        );

    }

 
    if( this.state.records === null)
    return(
        <div> 
        <h2>Generating your report</h2>
        <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
        </div>
    );


      var header;
      if(this.state.newitem===true)
      {
          return (
            <div>
              <h1>NOT ON SYSTEM</h1>
            </div>

          );
      }
      else
      {
        header = <h1>WRITE OFF STOCK</h1>
 
      }

     return (
       <div>
         {header}
      
        <Form>
        <Form.Group controlId="formSku">      

          <Form.Label>Item SKU</Form.Label>
          <Form.Control type="text" readOnly="True" value={this.state.sku} />
                    
        </Form.Group>

        <Form.Group controlId="formStockname">
          <Form.Label>Item Name</Form.Label>
          <Form.Control type="text" readOnly="True" value={this.state.name}/>
        </Form.Group>
   
   
        <Form.Group controlId="formStock" className={` ${this.errorClass(this.state.formErrors_stock)}`}>
      
          <Form.Label>Amount to write off </Form.Label>
          <Form.Control type="text" value={this.state.qty} onChange={this.onChangeQty.bind(this)} />
          <div className="ErrMsg"> {this.state.formErrors_stock}</div>
          </Form.Group>

          <Form.Group controlId="formStock" className={` ${this.errorClass(this.state.formErrors_reason)}`}>
          <Form.Label>Write off reason</Form.Label>
          <DropDownConstant apitarget="writeoffreasons" baseUrl={this.props.baseUrl} jwt={this.props.jwt} selected={this.state.brandid} onChangeDropDown={this.onChangeReason.bind(this)}/>
          <div className="ErrMsg"> {this.state.formErrors_reason}</div>
          </Form.Group>

        <Button type="button" variant="primary" onClick={this.handleSubmit.bind(this)} >  Submit  </Button>

      </Form>

       </div>
     )
   }
 }
 
 export default withRouter(WriteOff)
 