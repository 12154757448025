import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import {Button,ButtonToolbar} from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

export class ReportsMenu extends Component {
  render() {
    return (
      <div>
        <h1> Financial Reports </h1>

        <ButtonToolbar>
         
          <Button variant="success" block  onClick={this.handlebutton.bind(this,'/X1Report')}>X1 Report</Button>
          {(this.props.authlevel>1) && <div>
            <Button variant="success" block  onClick={this.handlebutton.bind(this,'/TakingsRptZ1')}>Z1 Report</Button>
            <Button variant="success" block  onClick={this.handlebutton.bind(this,'/TakingsRptZ2')}>Z2 Report</Button> 
          </div>}     
        </ButtonToolbar>


        <h1> Sales Reports </h1>
        <ButtonToolbar>
        <Button variant="info" block  onClick={this.handlebutton.bind(this,'/GetSales')}>Sales Report</Button>
        <Button variant="info" block  onClick={this.handlebutton.bind(this,'/TimeReport')}>Time</Button> 
        <Button variant="info" block  onClick={this.handlebutton.bind(this,'/GetTickets')}>Tickets</Button>
        </ButtonToolbar>

        <h1> Stock Reports </h1>

        <ButtonToolbar>

          <Button variant="primary" block  onClick={this.handlebutton.bind(this,'/StockSalesBetween')}>Between dates</Button>
          <Button variant="primary" block  onClick={this.handlebutton.bind(this,'/StockSalesAll')}>All time</Button>
          
          <Button variant="primary" block  onClick={this.handlebutton.bind(this,'/ManualEntry?path=/ItemReport&returnpath=/ReportsMenu&title=Item Report')}>Item Report</Button>
          <Button variant="primary" block  onClick={this.handlebutton.bind(this,'/ManualEntry?path=/StockMPN&returnpath=/ReportsMenu&Title=Stock Movement Report')}>Stock Movements</Button>
      
          <Button variant="primary" block  onClick={this.handlebutton.bind(this,'/BookInHistory')}>BookIn History</Button>
        
      

        </ButtonToolbar>

      </div>
    )
  }

  
  handlebutton(which,button)
  {
      console.log(which);
      this.props.history.push(which)
  }

}



export default withRouter(ReportsMenu)
