import React, { Component } from 'react'
import BootstrapTable  from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Button,ButtonToolbar} from 'react-bootstrap';


import { withRouter } from 'react-router-dom';
import axios from 'axios';
import AddUser from './EditAddUser';


export class ShowECRUsers extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            records: null,
        };
    }

    componentDidMount()
    {
        this.dodb();
    }

    dodb()
    {

        axios.get(this.props.baseUrl+'users/' , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
            this.setState({records: response.data.records});
            this.forceUpdate();
        })
        .catch(function (error) {
            console.log(error);
        })

    }

    handleadduser()
    {
        console.log(this);
        this.props.history.push('/Users/AddEdit?add=1')
    }

    render() {
        const columns = [ {
        dataField: 'userId',
        text: 'ID'
        }, 
        {
          dataField: 'userName',
          text: 'User Name',
         
        },
        {
          dataField: 'userEmail',
          text: 'Email',
        }
        ,
        {
          dataField: 'authLevel',
          text: 'Auth Level',
        }


        ];

    const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{this.props.name}</h3>;
    
    const handleRowClick2 = (row) => {

    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(`clicked on row with index: ${rowIndex}`);
        console.log(row);
        this.props.history.push('/Users/AddEdit?add=0&id='+row.userId);
      },
     
   }
    

    if(this.state.records)
        {
        return (
        <div>  

        <Button variant="danger" block  onClick={this.handleadduser.bind(this)}>Add User</Button>    

        <BootstrapTable
            keyField="id"  
            data={ this.state.records}
            columns={ columns } 
            striped 
            rowEvents={ tableRowEvents }
        />
            </div>
        )}else
        {
            return null;
        }
        
    }


    onreload()
    {
        this.dodb();
        this.forceUpdate();
    }
}

export default withRouter(ShowECRUsers)


    