import React, { Component } from 'react'
import axios from 'axios';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import MagicTable from "../Functional/magictable";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import {multiWordFilter,barcodeFilter} from "../multiwordfilter.js";


import {
  textFilter,
  selectFilter,
  dateFilter,
  numberFilter,
  Comparator
} from "react-bootstrap-table2-filter";


const selectBReason =[{ value: "Book In", label: "Book In"},{value: "Stock Take", label: "Stock Take"},{value: "Write Off" , label: "Write Off"}];
const selectWReason = [{ value: "Broken", label: "Broken"},{value: "Stolen", label: "Stolen"}];

export class Top100Value extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            sku: null,
            records: null,
            from: null,
            to: null,
            title: null,
            selectOptionssupplier: null,
            msg: "",
            progress: 0,
            error: null,
        }
    }

  componentDidMount()
  {
   
    this.setState({title: 'TOP 100 Report'});

    
    axios.get(this.props.baseUrl+'suppliers/', {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      console.log(response.data.records);

      var a=[];

      response.data.records.forEach(function(element)
      {
        a.push({value: element.name, label: element.name});

      });

      this.setState({selectOptionssupplier: a});

    });

/*
    axios.get(this.props.baseUrl+'department/', {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      console.log(response.data.records);

      var a=[];

      response.data.records.forEach(function(element)
      {
        a.push({value: element.name, label: element.name});

      });

      this.setState({selectOptionsdepartments: a});

    });
    */
  
    var method='post';
    var url = this.props.baseUrl+"execute/";
    var sql = "select stock_history.*, suppliers.name as suppliername, stock_details.selling_price, stock_details.company_price, stock_details.stock_name, stock_details.stock_quatity, stockchangereason.name as reason from stock_history left join stock_details on stock_details.barcode = stock_history.barcode left join suppliers on suppliers.id=stock_details.supplierid left join stockchangereason on stockchangereason.id=stock_history.reason";

    axios({
        method: method,
        url: url,
        data: sql,
        headers:
        {
          Authorization: `Bearer `+this.props.jwt,
        }
      }) .then(response => {


        if(response.data.Status==="ERROR")
        this.setState({error: response.data.error_info[2]});

        this.setState({records: response.data.records});
        this.forceUpdate();

      }) .catch(function (error) {
        console.log("SUBMIT ERROR");

        console.log(error);
    })
   }

  render() {

    if(this.state.error !== null)
    return (
    <div><p>
        {this.state.error};
        </p>
    </div>
    );


    if( this.state.records === null)
      return(
          <div> 
          <h2>Generating your report</h2>
          <p>Please wait......</p>
          <p>Loaded {this.state.progress} bytes</p>
          <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
          </div>
      );
 
    const columns = [{
      dataField: 'id',
      text: 'id',
      hidden: true
  }, {
    dataField: 'barcode',
    text: 'Barcode',
    sort: true,
    sortValue: (cell, row) => Number(cell),
    filter: textFilter({
      onFilter: barcodeFilter
    }),
  }, 
  {
    dataField: 'stock_name',
    text: 'Name',
    sort: true,
    filter: textFilter({
        onFilter: multiWordFilter
      })
  },
  {
    dataField: 'date',
    text: 'Date',
    sort: true,
    filter: dateFilter(),
  },
  {
    dataField: 'suppliername',
    text: 'Supplier',
    sort: true,
    filter: selectFilter({
        options: this.state.selectOptionssupplier
      })
  }
  ,{
    dataField: 'stock_quatity',
    text: 'Current stock level',
    sort: true,
    sortValue: (cell, row) => Number(cell) ,
    filter: numberFilter(),
  }
  ,{
    dataField: 'stock_level',
    text: 'Stock Level at bookin',
    sort: true,
    sortValue: (cell, row) => Number(cell) ,
    filter: numberFilter(),
  }
  ,{
    dataField: 'selling_price',
    text: 'Selling price',
    sort: true,
    sortValue: (cell, row) => Number(cell) ,
    filter: numberFilter(),
  }
  ,{
    dataField: 'company_price',
    text: 'Cost price',
    sort: true,
    sortValue: (cell, row) => Number(cell),
    filter: numberFilter(),
  }
  ,{
    dataField: 'reason',
    text: 'Reason',
    sort: true,
    sortValue: (cell, row) => Number(cell),
    filter: selectFilter({
        options: selectBReason,
      }),
  }
  ,{
    dataField: 'orderid',
    text: 'Order Id',
    sort: true,
    filter: textFilter({
        onFilter: multiWordFilter
      })
  }
  ,{
    dataField: 'invoiceid',
    text: 'Invoice Id',
    sort: true,
    sortValue: (cell, row) => Number(cell),
    filter: textFilter({
        onFilter: multiWordFilter
      })

  },
  {
    dataField: 'stock_update',
    text: 'New Stock',
    sort: true,
    sortValue: (cell, row) => Number(cell) ,
    filter: numberFilter(),
  },
  {
    dataField: 'writeoffreason',
    text: 'Write Off Reason',
    sort: true,  
    filter: selectFilter({
        options: selectWReason,
      }),  
  }
];

      return(
      <div>

      <h3></h3>

      <MagicTable
          {...this.props}
          data={this.state.records}
          columns={columns}
        />

      
      </div>
      
      )
 
     
  }
}

export default Top100Value