import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import {Button,Form} from 'react-bootstrap';
import queryString from 'query-string';

export class ManualEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sku: null,
            title: '',
            path: null,
            qs: null,
        }

    }
    
  handleSKUChange (e) {

       var sku = String(e.target.value); 
       sku = sku.replace(/\D/g,'');
        this.setState({sku: sku});
      }

    handleSubmit(e) {
        this.props.history.push(this.state.path+"?sku="+this.state.sku+this.state.qs);
    }

    handleCamera(e) {
      this.props.history.push("/Scanner?path="+this.state.path);
    }

    componentDidMount()
    {
        const values = queryString.parse(this.props.location.search)
        console.log("Values");
        console.log(values);

        var qs = "";
        Object.keys(values).forEach(key => {
          qs+="&"+key+"="+values[key];
        });

        console.log(qs);

        this.setState({path: values.path});
        this.setState({qs: qs});
        this.setState({title: values.title});
        this.setState({invoice: values.invoice});
        this.setState({order: values.orderno});
    }


  onFormSubmit = e => {
    e.preventDefault();
    this.handleSubmit.bind(this);
  }


  invoicestuff()
  {
    if(this.state.path === "/BookIn")
    {

      return (
        <div>
        <h3>Booking in new stock for</h3>
         <p>Invoice: {this.state.invoice}<br /> Order id: {this.state.order} </p>
         </div>
      );

    }
    else
    {
      return (
        <div></div>
      )
    }
   }


  render() {

    var is =   this.invoicestuff();

    return (
      <div>
        <h2>MANUAL ENTRY</h2>

       
        <h2>{this.state.title}</h2>
        
        {   is }

        <Form onSubmit={this.onFormSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Barcode/SKU</Form.Label>
          <Form.Control autoFocus={true} type="text" placeholder="Enter SKU" onChange={this.handleSKUChange.bind(this)}  />
        </Form.Group>

      
        <Button type="submit" variant="primary" onClick={this.handleSubmit.bind(this)} > 
          Submit
        </Button>

        
        <Button type="button" variant="primary" onClick={this.handleCamera.bind(this)} > 
          Camera
        </Button>

      </Form>
      </div>
    )
  }
}

export default withRouter(ManualEntry)
