import React, { useState } from "react";
import axios from 'axios';

import BootstrapTable from "react-bootstrap-table-next";
//import filterFactory from "../../local_modules/react-bootstrap-table2-filter";
import filterFactory from "react-bootstrap-table2-filter";


import ToolkitProvider, {
  ColumnToggle,
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button } from "react-bootstrap";

import { useRef } from "react";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const { ToggleList } = ColumnToggle;


const copytoclip = (tableid) => 
{

    const tableData = tableid.table.current.paginationContext.props.data;   
    const el = document.createElement('textarea');
    var text="";

    tableid.table.current.props.columns.forEach(element =>
    {
        if(tableid.table.current.props.columnToggle.toggles[element.dataField] === true)
        {
            text=text+element.dataField+"\t"; 
        }
    });

    text=text+"\n";

    for (const [key, value] of Object.entries(tableData)) {
 
        tableid.table.current.props.columns.forEach(element =>
            {
                if(tableid.table.current.props.columnToggle.toggles[element.dataField] === true)
                {
                    text=text+value[element.dataField]+"\t";
                }
            }
        );
        text=text+"\n";
     
    }

    el.value = text;
        
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    console.log('Table contents copied to clipboard!');
  }

const ExpandableRowComponent = ({ row, propsy, onClose }) => {

 

  const handleButton1Click = () => {
    window.open(
      "/ItemReport?sku=" + row.barcode,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleButton2Click = () => {
    window.open(
      "/EditItem?sku=" + row.barcode,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handlemuddleit = () => {

    var url="https://muddleit.com/index.php?route=product/search&search="+row.barcode+"&description=true";
    
    console.log("getting "+url);

    window.open(
      url,
      "_blank",
      "noopener,noreferrer"
    );
   


  }

  const handleCloseClick = () => {
    onClose();
  };

  const muddleit = (supplier) =>
  {
    if(supplier!=0)
    {
      return(<div></div>);
    }
    else
    {
      return(

    <Button variant="secondary" block onClick={handlemuddleit}>
    Get Muddleit data
    </Button>
      );
    }


  }

  return (
    <div>
      {`SKU  ${row.barcode}`}

      <Button variant="primary" block onClick={handleButton1Click}>
        Report
      </Button>
      <Button variant="primary" block onClick={handleButton2Click}>
        Edit
      </Button>
      <Button variant="secondary" block onClick={handleCloseClick}>
        Close
      </Button>

      {muddleit(row.supplierid)}

    </div>
  );
};

const MagicTable = (propsy) => {
  const table = useRef(null);

  console.log("MAGIC TABLE");

  const onColumnMatch = ({ searchText, value, column, row }) => {
    var filterVal = String(searchText).toLowerCase();
    filterVal = filterVal.replaceAll("é", "e");
    filterVal = filterVal.replaceAll("-", "");

    filterVal = filterVal.replaceAll("s", "");


    const filterWords = filterVal.split(" ");

    var xx = Array(row);

    var yyy = xx.filter((row2) => {
      return filterWords.every((word) => {
        return Object.values(row2).some((value) => {
          let x = String(value);
          x = x.replaceAll("é", "e");
          x = x.replaceAll("-", "");
          x = x.replaceAll("s", "");
          
          var y = x.toLowerCase().includes(word);
          return y;
        });
      });
    });

    return yyy.length > 0;
  };

  const ClearButton = (props) => {
    const handleClick = () => {
      props.onSearch("");
      //props.clearAllFilter();
    };

    return (
      <Button
        variant="secondary"
        onClick={handleClick}
        style={{
          fontSize: "16px",
          padding: "5px",
          margin: "10px",
          height: "40px",
        }}
      >
        Clear
      </Button>
    );
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowExpand = (row, isExpand, rowIndex, e) => {
    setExpandedRows([row.id]);
  };

  const handleRowCollapse = (row, isExpand, rowIndex, e) => {
    setExpandedRows([]);
  };

  const handleRowClose = (rowId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.filter((id) => id !== rowId)
    );
  };

  const expandRow = {
    renderer: (row) => (
      <ExpandableRowComponent
        row={row}
        propsy={propsy}
        onClose={() => handleRowClose(row.id)}
      />
    ),
    expanded: expandedRows,
    onExpand: handleRowExpand,
    onCollapse: handleRowCollapse,
  };

  const MyExportCSV = (propsx, tablex) => {
    const handleClick = () => {

       console.log(table.current.paginationContext.props.data);

      if (table.current.sortContext.state.sortOrder) {
        propsx.onExport(table.current.paginationContext.props.data);
      } else {
        propsx.onExport();
      }
    };
    return (
      <Button variant="primary" block onClick={handleClick}>
        Export CSV
      </Button>
    );
  };

  const cfv = (cell,row) =>
  {
    console.log(cell);
    console.log(row);

  }

  const MyCopyClip = (table) =>
  {
    const handleClick = () => 
    {
        copytoclip(table);
    }

    return (
        <Button variant="primary" block onClick={handleClick}>
          Copy
        </Button>
      );
  }

  return (
    <div>
      <ToolkitProvider
        bootstrap4
        keyField="barcode"
        data={propsy.data}
        columns={propsy.columns}
        search={{ onColumnMatch }}
        delay={1000}
        columnToggle
        exportCSV={{
          fileName: "Inspire.csv",
          onlyExportFiltered: true,
          exportAll: false,
          ignoreHeader: false,
          noAutoBOM: false,
        }}
      >
        {(props) => (
          <div>
            <SearchBar
              {...props.searchProps}
              style={{ width: "400px", height: "40px" }}
            />

            <ClearButton
              {...props.searchProps}
              //clearAllFilter={clearAllFilter}
            />

            <ToggleList {...props.columnToggleProps} />

            <MyExportCSV
              className="btn btn-secondary"
              {...props.csvProps}
              tablex={table}
            >
              Export CSV
            </MyExportCSV>

            <MyCopyClip table={table} />

            <BootstrapTable
              {...props.baseProps}
              ref={table}
              keyField="id"
              data={propsy.data}
              expandRow={expandRow}
              columns={propsy.columns}
              filter={filterFactory()}
              noDataIndication="No data for current search or filters"
              striped
              pagination={paginationFactory({
                sizePerPageList: [
                  {
                    text: "10",
                    value: 10,
                  },
                  {
                    text: "50",
                    value: 50,
                  },
                  {
                    text: "100",
                    value: 100,
                  },
                  {
                    text: "250",
                    value: 250,
                  },
                  {
                    text: "500",
                    value: 500,
                  },
                  {
                    text: "1000",
                    value: 1000,
                  },
                ],
                sizePerPage: 100,
                onPageChange: (page) => console.log("DB CALL with page" + page),
              })}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default MagicTable;
