import React, { Component } from 'react'
import {Button,Form} from 'react-bootstrap';
import axios from 'axios';
import ReactLoading from 'react-loading';



export class Login extends Component {
    constructor(props) {
        super(props);

      console.log(this)

        this.state = {
            password: null,
            username: null,
            error: null,
            loggingin: false,
        }
    }

    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


    

     handleEmailChange (e) {
        this.setState({username: e.target.value});
      }
      
      handlePasswordChange(e) {
        this.setState({password: e.target.value});
      }

      handleReset(e) {
        this.props.history.push('/ResetPassword');
      }
      
      handleLogin(e) {
       
       

        var u="";
        if(this.props.baseUrl==="https://shop.byteme.org.uk/crudoff/2.0/")
        {
          u = "https://shop.byteme.org.uk";
        }

        if(this.props.baseUrl==="http://localhost/crudoff/2.0/")
        {
          u = "http://localhost/";
        }

        if(this.props.baseUrl==="https://shoptest.byteme.org.uk/crudoff/2.0/")
        {
          u = "https://shoptest.byteme.org.uk";
        }
          //u+
        console.log("Getting data...");

        this.setState({loggingin: true});
        axios.get(u+"/crudoff/login.php?username="+this.state.username+"&password="+this.state.password)
          .then(response => {

            this.setState({loggingin: false});

            if(response.data.jwt)
            {
              this.props.updatejwt(response.data.jwt);
              this.setCookie("auth",response.data.jwt);
              this.setCookie("username",response.data.username);
              this.setCookie("authlevel",response.data.authlevel);
              
              console.log("setting auth cookie auth cookie to "+response.data.jwt);
              this.props.updatejwt(response.data.jwt,response.data.username,response.data.authlevel);
            }
            else
            {
              console.log("Network fail");
              this.setState({error: 'Network failure'});
            }

          })
          .catch(error => {
           
            this.setState({loggingin: false});

            if(error && error.response && error.response.status)
            {
              console.log("Auth fail");
              console.log(error.response);
              this.setState({error: error.response.statusText});
            }
            else
            {
              this.setState(state=> ({ ...state, error: "Unknown error occued, may be network failure"}));
            }

           
          });


      
      }


  render() {

    if(this.state.loggingin===true)
    {
      return(
        <div>
      <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
      </div>
      );
    }

    
    return (
      <div>
        <h1>You must log in to use this service</h1>


      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>User name</Form.Label>
          <Form.Control type="text" placeholder="Enter username" onChange={this.handleEmailChange.bind(this)} />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
          {this.state.error?<Form.Label className="Error">{this.state.error}</Form.Label>:null} 
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" autoComplete="on" onChange={this.handlePasswordChange.bind(this)} />

          {this.state.error?<Form.Label className="Error">{this.state.error}</Form.Label>:null} 

        </Form.Group>
       
        <Button variant="primary" onClick={this.handleLogin.bind(this)} > 
          Submit
        </Button>

        <Button variant="secondary" onClick={this.handleReset.bind(this)} > 
          Reset Password
        </Button>
  
      </Form>;

      </div>
    )
  }
}

export default Login
