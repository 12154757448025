import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import {Button,ButtonToolbar} from 'react-bootstrap';

import { withRouter } from 'react-router-dom'


export class MainMenu extends Component {
  render() {
    return (
      <div>
          <ButtonToolbar>
            <Button variant="primary" block onClick={this.handlebutton.bind(this,'Reports')}>Reports</Button>
            <Button variant="primary" block onClick={this.handlebutton.bind(this,'Actions')}>Actions</Button>
            <Button variant="danger" block onClick={this.handlebutton.bind(this,'Logout')}>Logout</Button>
            
          </ButtonToolbar>
      </div>
    )
  }

  handlebutton(which)
  {
     
      switch(which)
      {
          case "Reports":
            this.props.history.push('/ReportsMenu');
            break;
          case "Actions":
            this.props.history.push('/Actions');
            break;
            case "Logout":
              this.props.updatejwt(null);
              break;
          default:
          break;
      }
  }

}

export default withRouter(MainMenu)
