import React, { Component } from 'react'

import BootstrapTable  from 'react-bootstrap-table-next';
import {Button} from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

export class ItemList extends Component {
   

  handlebutton(which,target)
  {
    //this.props.history.push(which)
    window.open(which, '_blank', 'noopener,noreferrer');

  }
  
  render() {



    const columns = [ {
      dataField: 'stock_name',
      text: 'Product Name',
      sort: true,

      sortFunc: (a, b, order, dataField, rowA, rowB) => {

        const myArrayA = a.split(" ");
        const myArrayB = b.split(" ");

        if (order === 'asc') {

          var r = myArrayA[0].localeCompare(myArrayB[0]);

          console.log( myArrayA[0]);
          console.log( r);
          
          if(r!==0)
          {
            return r;
          }
          else
          {
            console.log(rowA['delta']);
            console.log(rowB['delta']); 
            return rowB['delta'] - rowA['delta'];
          }

          
              
        }
        var r2 = myArrayB[0].localeCompare(myArrayA[0]);
        if(r2!==0)
        {
          return r2;
        }
        else
        {
            return rowB['delta'] - rowA['delta'];
        }
        
      }

    }, {
      dataField: 'price',
      text: 'Product Price',
      sort: true
    },{
      dataField: 'delta',
      text: 'Qty',
      sort: true
    },{
      dataField: 'barcode',
      text: 'Barcode',
      sort: true
    } ,{
      dataField: 'stock_quatity',
      text: 'Stock',
      sort: true
    },
    {
      dataField: 'company_price',
      text: 'Cost price',
      sort: true
    },
    {
      dataField: 'department',
      text: 'Department',
      sort: true
    },
    {
      dataField: 'supplier',
      text: 'Supplier',
      sort: true
    }


    
  ];

  const columns2 = [ {
    dataField: 'stock_name',
    text: 'Product Name',
    sort: true,

    sortFunc: (a, b, order, dataField, rowA, rowB) => {

      const myArrayA = a.split(" ");
      const myArrayB = b.split(" ");

      if (order === 'asc') {

        var r = myArrayA[0].localeCompare(myArrayB[0]);

        console.log( myArrayA[0]);
        console.log( r);
        
        if(r!==0)
        {
          return r;
        }
        else
        {
          console.log(rowA['delta']);
          console.log(rowB['delta']); 
          return rowB['delta'] - rowA['delta'];
        }

        
            
      }
      var r2 = myArrayB[0].localeCompare(myArrayA[0]);
      if(r2!==0)
      {
        return r2;
      }
      else
      {
          return rowB['delta'] - rowA['delta'];
      }
      
    }

  },
  {
    dataField: 'barcode',
    text: 'Barcode',
    sort: true
  }, 
  
  {
    dataField: 'selling_price',
    text: 'Product Price',
    sort: true
  },
  {
    dataField: 'company_price',
    text: 'Cost price',
    sort: true
  },
  {
    dataField: 'stock_quatity',
    text: 'Stock',
    sort: true
  },
  {
    dataField: 'departmentname',
    text: 'Department',
    sort: true
  },
  {
    dataField: 'suppliername',
    text: 'Supplier',
    sort: true
  }


  
];


    const expandRow = {
      renderer: row => (
        <div>
          { `SKU  ${row.barcode}` }  
          <Button variant="primary" block onClick={this.handlebutton.bind(this,`/ItemReport?sku=${row.barcode}`)}>Report</Button>
          <Button variant="primary" block onClick={this.handlebutton.bind(this,`/EditItem?sku=${row.barcode}`)}>Edit</Button>

        </div>
      )
    };

  
    const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{this.props.name}</h3>;
  
if(this.props.items && this.props.dpt)
 {   return (
      <div>
    <CaptionElement />
    <Button className="button2" variant="danger" block onClick={this.copytoclip.bind(this)}>Copy</Button>
    <BootstrapTable
      id={"thetable"+this.props.dpt}
      keyField="barcode" 
      data={ this.props.items.filter(item => item.department === this.props.dpt) }
      //data={ this.props.items }
      columns={ columns }
      expandRow={ expandRow }
      striped
    />
  
      </div>
    )
 }
    else if(this.props.items)
    {

      console.log("ItemList!")
      console.log(this.props);

      if(this.props.cols === '2')
      {
        return (
          <div>  
          <CaptionElement />
          <Button className="button2" variant="danger" block onClick={this.copytoclip.bind(this)}>Copy</Button>
          <BootstrapTable
            id={"thetable"+this.props.dpt}
            keyField="barcode"  
            data={ this.props.items }
            columns={ columns2 }
            expandRow={ expandRow }
            striped
          />
            </div>
          )
      }
      else
      {

      
        return (
        <div>  
        <CaptionElement />
        <Button className="button2" variant="danger" block onClick={this.copytoclip.bind(this)}>Copy</Button>
        <BootstrapTable
          id={"thetable"+this.props.dpt}
          keyField="barcode"  
          data={ this.props.items }
          columns={ columns }
          expandRow={ expandRow }
          striped
        />
          </div>
        )
      }
    }
    else
    {
      return (<div><h2>NO DATA</h2></div>) 
    }
  }

  copytoclip()
  {
  
    console.log('thetable'+this.props.dpt);

    const elTable = document.getElementById('thetable'+this.props.dpt);
  
  let range, sel;
  
  // Ensure that range and selection are supported by the browsers
  if (document.createRange && window.getSelection) {
  
    range = document.createRange();
    sel = window.getSelection();
    // unselect any element in the page
    sel.removeAllRanges();
  
    try {
      range.selectNodeContents(elTable);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(elTable);
      sel.addRange(range);
    }
  
    document.execCommand('copy');
  }

}
}

export default  withRouter(ItemList)
