import React, { Component } from 'react'
import MyDatePicker from './MyDatePicker';
import { withRouter } from 'react-router-dom';
import DateRangeSelector from './DateRangeSelector';

export class DateSelWrapper extends Component {
    constructor(props) {
        super(props);
    
        this.state = 
        {
                newtarget: null,

        }
    }

    componentDidMount()
    {

        this.setState({newtarget: this.props.location.pathname })

    }

    dateselected(selectedDateRange)
    {

        if(selectedDateRange)
        {
            var fromdate = selectedDateRange.startDate.getFullYear()+"-"+(selectedDateRange.startDate.getMonth()+1)+"-"+selectedDateRange.startDate.getDate()
            var todate = selectedDateRange.endDate.getFullYear()+"-"+(selectedDateRange.endDate.getMonth()+1)+"-"+selectedDateRange.endDate.getDate()
            console.log("Date selection From "+fromdate+" to "+todate);
            console.log(this);
            this.props.history.push(this.state.newtarget+"?from="+fromdate+"&to="+todate);
        }
        else
        { 
            this.props.history.push('/')
        }
    }

    render() {
    
    if(this.props.location.search==="")
    {
        return (
            <div>
                {/*<MyDatePicker newtarget={this.props.location.pathname}/> */}
                <DateRangeSelector onSubmit={this.dateselected.bind(this)} />
            </div>
        )
    }
    else
    {
        return(<div>{this.props.children}</div>);
    }

   
  }
}

export default withRouter(DateSelWrapper)
