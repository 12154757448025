import React, { Component } from 'react'
import PropTypes from 'prop-types';

//import { BarcodeFormat, DecodeHintType, BrowserMultiFormatReader } from '@zxing/library';

export class Scanner2 extends Component {

    constructor(props) {
        super(props);

        this._onDetected = this._onDetected.bind(this);  

        this.scancount = 0;
        
        this.state = {
            lastresult: 0,
            samecount: 0 
        };
    }


    render() {
        return (
            <video id="interactive" className="viewport" width="600" height="400"/>
        );
    }

    componentDidMount() {

        /*
        const hints = new Map();
        const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX];
        hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

        
        const codeReader = new BrowserMultiFormatReader()
        
        console.log('ZXing code reader initialized')

        const constraints = window.constraints = {
            audio: false,
            video: {facingMode: { exact: "environment" } },
        };


        codeReader.listVideoInputDevices() 
        .then((videoInputDevices) => {

            console.log("There are "+videoInputDevices.length+" devices");
           

            codeReader.decodeOnceFromConstraints(constraints,'interactive' , (result, err) => {

           // codeReader.decodeFromVideoDevice(selectedDeviceId, 'interactive', (result, err) => {
                if (result) {
                  console.log(result)
                  //document.getElementById('result').textContent = result.text
                  this._onDetected(result);
                  
                  codeReader.reset();
                  
                }
            })
    
        })

        */
    }

    _onDetected(result) 
    {
        
        this.setState({lastresult: result.text});
        this.props.onDetected(result.text);
    }

    componentWillUnmount() {

    }

    

};

Scanner2.propTypes = {
    onDetected: PropTypes.func.isRequired
};

export default Scanner2
