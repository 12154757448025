import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';

export class TicketTotal extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
        }
    }
  
  componentDidMount()
  {
    console.log(this);

    var url = this.props.baseUrl+'tickettotal/?date='+this.props.date+'&to='+this.props.to+"/";

    console.log(url);
    
    axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
          console.log("TICKET TOTAL RESPONSE");
          console.log(response.data.records);
        this.setState({records: response.data.records});
        })
        .catch(function (error) {
          console.log("TICKET TOTAL ERROR");
        console.log(error);
        })
  }

  render() {
    
    if(!this.state.records)
         return(<h1>NO TOTAL</h1>);
 

    console.log("RENDERING TICKET TOTAL");
    console.log(this.state.records[0].total);
    return (
      <div>
          <p>Total Takings = £{this.state.records[0].total}<br />
          Total Tickets = {this.state.records[0].count} <br />
          ATV = £{ (Math.round(this.state.records[0].ATV * 100) / 100).toFixed(2) } </p>
          
      </div>
    )


  }
}

export default withRouter(TicketTotal)
