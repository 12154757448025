import React, { Component } from 'react'
import axios from 'axios';
import queryString from 'query-string';
import BootstrapTable  from 'react-bootstrap-table-next';
import ReactLoading from 'react-loading';

 export class GetTakings extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
            from: null,
            to: null,
            title: null,
        }
    }

    componentDidMount()
    {
        const searchvalues = queryString.parse(this.props.location.search)
        this.setState({from: searchvalues.from});
        this.setState({to: searchvalues.to});

        var url = this.props.baseUrl+this.props.type+'?start='+searchvalues.from+'&end='+searchvalues.to

        if(this.props.type==="Z1")
            this.setState({title: 'Z1 Reports'})
        if(this.props.type==="Z2")
            this.setState({title: 'Z2 Reports'})

        axios.get(url , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
          console.log(response);
          this.setState({records: response.data.records});
          this.forceUpdate();
        })
        .catch(function (error) {
          console.log(error);
        })
    }

   render() {

    if( this.state.records === null)
    return(
        <div> 
        <h2>Generating your report</h2>
        <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
        </div>
    );

   
    const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{this.state.title}</h3>;
 
    const columns = [{
          dataField: 'id',
          text: 'id',
          hidden: true
      }, {
        dataField: 'date',
        text: 'Date'
      }, 
      {
        dataField: 'ecrid',
        text: 'ECR'
      }, 
      {
        dataField: 'PLU_TTL_value',
        text: 'PLU TTL'
      }
      ,{
        dataField: 'PLU_TTL_count',
        text: 'count'
      }
      ,{
        dataField: 'GROSSSALES_value',
        text: 'GROSSSALES_value'
      }
      ,{
        dataField: 'CASHSALES_value',
        text: 'CASHSALES_value'
      }
      ,{
        dataField: 'CHG1_SALES_value',
        text: 'CHG1_SALES_value'
      }
      ,{
        dataField: 'DISCOUNT1_value',
        text: 'DISCOUNT1_value'
      }
      ,{
        dataField: 'DISCOUNT3_value',
        text: 'DISCOUNT3_value'
      }
      ,{
        dataField: 'NETSALE_value',
        text: 'DISCOUNT3_value'
      }
      ,{
        dataField: 'NON_TAX_value',
        text: 'DISCOUNT3_value'
      } 
      ,{
        dataField: 'TAX1_SALES_value',
        text: 'DISCOUNT3_value'
      }
      ,{
        dataField: 'TAX2_SALES_value',
        text: 'DISCOUNT3_value'
      }
      ,{
        dataField: 'TAX1_value',
        text: 'DISCOUNT3_value'
      }
      ,{
        dataField: 'TAX2_value',
        text: 'DISCOUNT3_value'
      }

    ];

     return (
       <div>
        <CaptionElement />
                
          <BootstrapTable
            keyField="id"
            data={ this.state.records}
            columns = {columns }
            striped
            />
        
       </div>



     )
   }
 }
 
 export default GetTakings
 