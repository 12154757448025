import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

import BootstrapTable  from 'react-bootstrap-table-next';
import TicketLine from '../TicketLine.js';
import { TicketTotal } from '../TicketTotal.js';

export class GetTickets extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
        }
    }

    componentDidMount()
    {

        if(this.props.location.search==="")
        return;

        const searchvalues = queryString.parse(this.props.location.search)
        this.setState({from: searchvalues.from});
        this.setState({to: searchvalues.to});

        axios.get(this.props.baseUrl+'ticket/?start='+searchvalues.from+'&end='+searchvalues.to , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
        this.setState({records: response.data.records});
        this.forceUpdate();
        })
        .catch(function (error) {
        console.log(error);
        })
    }

  render() {

    if(this.state.records==null)
    return(
        <div></div>
    )

    const columns = [{
            dataField: 'id',
            text: 'Id',
            hidden: true,
           
      }, {
        dataField: 'dt',
        text: 'Date'
      }, {
        dataField: 'ticketTotal',
        text: 'Total'
      },{
        dataField: 'clark',
        text: 'Clerk'
      }];

      const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{"Ticket report"}</h3>;
  
      const expandRow = {
        renderer: row => (
          <div>
          
          <TicketLine id={row.id} jwt={this.props.jwt} baseUrl={this.props.baseUrl}  />
         
          </div>
        )
      };

    return (
      <div>

      <TicketTotal date={this.state.from} to={this.state.to} jwt={this.props.jwt} baseUrl={this.props.baseUrl}/>


         <CaptionElement />

        <BootstrapTable
        keyField="id"
        data={ this.state.records }
        columns={ columns }
        striped={true}
        expandRow={ expandRow }
        />
      </div>
    )
  }
}

export default withRouter(GetTickets)
