import React, { Component } from 'react'
import { withRouter,Redirect } from 'react-router-dom';

import { Button, Form } from 'react-bootstrap';

import queryString from 'query-string';
import axios from 'axios';
import ReactLoading from 'react-loading';

import Modalmsg from '../modalmsg';
import Okmsg from '../Okmsg';

export class BookInPre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceid: '',
      orderid: '',
      hasErrors: false,
      formErrors_invoice: '',
      redirect: null,
    }
  }

  componentDidMount() {

    const searchvalues = queryString.parse(this.props.location.search);
    this.setState({ sku: searchvalues.sku });
  }

  onChangeInvoiceNo(e) {
    this.setState({ invoiceid: e.target.value });
  }

  onChangeOrderNo(e) {
    this.setState({ orderid: e.target.value });
  }


  handleSubmit() {
    this.setState({ hasErrors: false });
    this.setState({ formErrors_invoice: '' });


    console.log("Submit");

    var hasErrors = false;

    if (this.state.invoiceid === "" && this.state.orderid === "") {
      this.setState({ formErrors_invoice: 'Must have either invoice and/or order no' });
      hasErrors = true;
    }

    this.setState({ hasErrors: hasErrors });

    if (hasErrors === true) {
      return;
    }

    var url;
    var method;

    url = "/ManualEntry?path=/BookIn&invoice="+this.state.invoiceid+"&orderno="+this.state.orderid;
    this.setState({redirect: url});
  }


  errorClass(error) {

    if (!error)
      return '';
    return (error.length === 0 ? '' : 'has-error');
  }

  


  render() {

   
    if(this.state.redirect != null)
    {
      return(
      <Redirect to={this.state.redirect} />
      );
    }

   
    return (
      <div>
        <h1>Book in stock</h1>

        <Form>

          <Form.Group controlId="formStock" className={` ${this.errorClass(this.state.formErrors_invoice)}`}>
            <Form.Label>Invoice No</Form.Label>
            <Form.Control type="text" value={this.state.invoiceid} onChange={this.onChangeInvoiceNo.bind(this)} />
            <div className="ErrMsg"> {this.state.formErrors_invoice}</div>

            <Form.Label>Order No</Form.Label>
            <Form.Control type="text" value={this.state.orderid} onChange={this.onChangeOrderNo.bind(this)} />
            <div className="ErrMsg"> {this.state.formErrors_invoice}</div>
          </Form.Group>

          <Button type="button" variant="primary" onClick={this.handleSubmit.bind(this)} >
            Submit
          </Button>


        </Form>

      </div>
    )
  }
}

export default withRouter(BookInPre)
