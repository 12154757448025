import React, { Component } from 'react'
import {Button,ButtonToolbar} from 'react-bootstrap';

export class Actions extends Component {
  render() {


    let users;
    let users2;

    if(this.props.authlevel>1)
    {
      users=<Button variant="primary" block  onClick={this.handlebutton.bind(this,'/ECRUsers/ShowAll')}>ECR Users</Button>;
      users2=<Button variant="primary" block  onClick={this.handlebutton.bind(this,'/Users/ShowAll')}>Users</Button>;
    }
    else
    {
        users="";
    }

    return (
      <div>
        <h1> Stock Actions </h1>
        
        
        <ButtonToolbar> 
          <Button variant="success" block  onClick={this.handlebutton.bind(this,'/BookInPre')}>Book In Stock</Button>          
       
          <Button variant="danger" block  onClick={this.handlebutton.bind(this,'/ManualEntry?path=/Writeoff&title=Write Off')}>Write off stock</Button>          
      
          <Button variant="warning" block  onClick={this.handlebutton.bind(this,'/ManualEntry?path=/EditItem&title=Edit Item')}>Edit Item</Button>          
      
          <Button variant="primary" block  onClick={this.handlebutton.bind(this,'/ItemSearch')}>Search</Button>          
     
        
        </ButtonToolbar>;

      
        <h1> Admin Actions </h1>
        <ButtonToolbar> 
   
        <Button variant="primary" block  onClick={this.handlebutton.bind(this,'/Suppliers/ShowAll')}>Suppliers</Button>
        {users}
        {users2}

        </ButtonToolbar>;
   


      </div>
    )
  }

  handlebutton(which,button)
  {

     if(which=="/Logout")
     {
      console.log("Clear cookies");
      document.cookie = "auth=";
      this.props.history.push("/");
     }
     
      console.log(which);
      this.props.history.push(which)
  }

}

export default Actions
