import React, { Component } from 'react'

import BootstrapTable  from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Button } from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';

export class SupplierList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount()
    {
        console.log(this);
        
    }


    buttondelete(id)
    {

        console.log("Delete on "+id);
        confirmAlert({
            title: 'Confirm Delete?',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => { 
                    
                    axios.delete(this.props.baseUrl+'suppliers/'+id+"/" , {headers: { Authorization: `Bearer `+this.props.jwt }})
                    .then(response => {          
                        this.props.onreload();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                
                }
              },
              {
                label: 'No',
             
              }
            ]
          })  
    }

    handleChange(oldValue, newValue, row, column) 
    {

      if(newValue == '' || newValue == null)
        return;

      axios(
        {
            method: 'patch',
            url: this.props.baseUrl+"suppliers/"+row.id+'/',
            data: {
              name: newValue, 
              id: row.id
            },
            headers:
            {
              Authorization: `Bearer `+this.props.jwt,
            }
          })
      .then(response => {        
          this.props.onreload();
      })
      .catch(function (error) {
          console.log(error);
      });

    }

    handlebutton(path)
    {
      this.props.history.push(path);
    }

    render() {
        const columns = [ {
        dataField: 'id',
        text: 'ID'
        }, {
        dataField: 'name',
        text: 'Supplier'
        }
        ];

    const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>{this.props.name}</h3>;
    

    var expandRow

    if(this.props.authlevel>1)
    {

    expandRow = {
      renderer: row => (
        <div>
          <Button variant="primary" block onClick={this.buttondelete.bind(this,`${row.id}`)}>Delete</Button>
          <Button variant="primary" block onClick={this.handlebutton.bind(this,`/Suppliers/AddEdit?id=${row.id}`)}>Edit</Button>

        </div>
      )
    };
  }

   

    if(this.props.items)
        {
        return (
        <div>  
        
        <CaptionElement  />
        <BootstrapTable
            keyField="id"  
            data={ this.props.items }
            columns={ columns } 
            striped
            expandRow={ expandRow }
           
            //cellEdit={ cellEditFactory({
            //  mode: 'dbclick',
            //  beforeSaveCell: this.handleChange.bind(this),
            //})}
            
        />
            </div>
        )}else
        {
            return null;
        }
        
    }
}

export default withRouter(SupplierList)


    