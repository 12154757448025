import React, { Component } from 'react'
import {Button,Form} from 'react-bootstrap';
import axios from 'axios';
import ReactLoading from 'react-loading';



export class ResetPassword extends Component {
    constructor(props) {
        super(props);

      console.log(this)

        this.state = {
          username: "",
          error: "",
          reset: false,
        }
    }

  
doreset()
{
  console.log("DO RESET");

  axios.get(this.props.baseUrl2+"crudoff/reset.php?username="+this.state.username)
          .then(response => {

            console.log("success");

            console.log(response);

            this.setState({reset: true});
           
          })
          .catch(error => {
    
            if(error && error.response && error.response.status)
            {
              console.log("reset fail");
              console.log(error.response);
              this.setState({error: error.response.statusText});
            }
            else
            {
              this.setState(state=> ({ ...state, error: "Unknown error occued, may be network failure"}));
            }

           
          });



}

handleUsernamechange (e) {
  this.setState({username: e.target.value});
}


  render() {

    if(this.state.reset===true)
    {
      return(
        <div>
      <p>Providing that was a valid user name, an email will have been sent to the registered email address with a reset link.</p>

      <Button variant="primary" onClick={this.props.history.push('/')} > 
          Login
        </Button>

      </div>
      );
    }

    
    return (
      <div>
        <h1>Reset Password</h1>


      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>User name</Form.Label>
          <Form.Control type="text" placeholder="Enter username" onChange={this.handleUsernamechange.bind(this)} />
         
         
        </Form.Group>

       
       
        <Button variant="primary" onClick={this.doreset.bind(this)} > 
          Submit
        </Button>

       
  
      </Form>;

      </div>
    )
  }
}

export default ResetPassword
