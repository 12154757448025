import React, { Component } from 'react'
import axios from 'axios';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import distinctColors from 'distinct-colors'
import { ResponsiveContainer, YAxis,Legend, ScatterChart,Scatter,CartesianGrid,XAxis} from 'recharts'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';

export class SalesByDptGraph extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
            departments: null,
            bydepartment: null,
            from: null,
            to: null,
            piechartdata: null,
            totalSales_amt: null,
            totalSales_cnt: null,
            fetchrequested: false,
            bydepartment2: null,
            bydepartmenttotal: null,
            
            palette: null,
            colors: null,
            showdots: 0,

        };
    }

    componentDidMount()
    {

        var p = distinctColors({count: 15 });

        var c = [];
        var i=1;
    
        p.forEach(function(element){ 
          var colorstring = "#"+Math.round(p[i-1]._rgb[0]).toString(16) + Math.round(p[i-1]._rgb[1]).toString(16) + Math.round(p[i-1]._rgb[2]).toString(16);
          c[i] = colorstring; 
          i++;
        });
    
        this.setState({palette: p });
        this.setState({colors: c });

        const searchvalues = queryString.parse(this.props.location.search)
        this.setState({from: searchvalues.from});
        this.setState({to: searchvalues.to});


        let date_1 = new Date(searchvalues.from);
        let date_2 = new Date(searchvalues.to);
        
        const days = (date_1, date_2) =>{
            let difference = date_1.getTime() - date_2.getTime();
            let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
            return TotalDays;
        }
        
       // console.log("DAYS BEWEEN ARE "+days(date_2,date_1));
        if(days(date_1,date_2)<10)
        {
          this.setState({showdots: 1})
        }
        else{
          this.setState({showdots: 0})
        }


        axios.get(this.props.baseUrl+'sales?bydpt2&start='+searchvalues.from+'&end='+searchvalues.to , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
         
    
          var data1 = [];
          var data2 = [];
    
          for (let i = 0; i < 15; i++) {
            data1[i] = [];
          }
    
         
          response.data.records.forEach(function(element){ 
    
            var d = new Date(element.date);
    
            if(data1[element.department])
            {
              data1[element.department].push({Date: d, Takings: element.total})
            }
    
          //  console.log("Adding "+element.total+" to "+d);
    
            var num1 = 0;
            var num2 = 0;
            if(data2[d])
            {
              num1 = parseInt(data2[d]);
            }
          
            if(element.total)
            {
              num2 = parseInt(element.total);
            }
    
            data2[d]=num1+num2;
            
          });
    
    
          var data3 = [];
    
          Object.keys(data2).forEach(key => {
           // console.log("KEY is "+key+" data is "+data2[key]);
            data3.push({Date: new Date(key), Takings: data2[key]})
          });
    
          
         // console.log(data1);
         // console.log(data3);
          
        
    
          this.setState({bydepartment2: data1});
          this.setState({bydepartmenttotal: data3});
          
    
        });

    }

    showdots()
    {
      if(this.state.showdots === 0)
        return null;
      else
        return 'point';

    }

    render() 
    {

        if(!this.state.bydepartment2)
        return (
            <div> 
            <h2>Generating by dpt graph</h2>
            <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
            </div>
        )
  
  
        return (
          <div>
          <ResponsiveContainer width = '95%' height = {400} >
  
      <ScatterChart
      margin={{
        top: 20, right: 20, bottom: 20, left: 20,
      }}
      >
      <CartesianGrid />
      <XAxis dataKey="Date" name="Date" unit=""  domain = {['auto', 'auto']} tickFormatter = {(unixTime) => moment(unixTime).format('DD:MM:YYYY')} type = 'Date' />
      <YAxis type="number" dataKey="Takings" name="Takings" unit="£"  domain={[0, 'dataMax']} />
  
      <Legend />
  
      <Scatter name="Collectables" data={this.state.bydepartment2[2]} fill={this.state.colors[2]} line shape={this.showdots()} />
      <Scatter name="Toys" data={this.state.bydepartment2[3]} fill={this.state.colors[3]} line shape={this.showdots()} />
      <Scatter name="Food" data={this.state.bydepartment2[13]} fill={this.state.colors[13]} line shape={this.showdots()} />
      
      <Scatter name="Total" data={this.state.bydepartmenttotal} fill={this.state.colors[1]} line shape={this.showdots()} />
      
  
      </ScatterChart>
      </ResponsiveContainer>
      </div>
  
  
        );


    }

}

SalesByDptGraph.propTypes = {
    jwt: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired
  };

  export default withRouter(SalesByDptGraph)