import React, { Component } from 'react'
import Scanner2 from './Scanner2';
import queryString from 'query-string';
import {Button,ButtonToolbar} from 'react-bootstrap';
import { withRouter } from "react-router-dom";
        
export class ScannerControl2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scanning: false,
            results: 0,
            path: null,
     
        };

        this._scan = this._scan.bind(this);
        this._onDetected = this._onDetected.bind(this);
      
    }

    componentDidMount()
    {
        const values = queryString.parse(this.props.location.search)
        this.setState({path: values.path});
        this.setState({returnpath: values.returnpath});
        this.setState({scanning: true});
    }

    render() {
        return (
            <div>
                {this.state.scanning ? <Scanner2 onDetected={this._onDetected}/> : null}
                <ButtonToolbar className="belowbuf">
                    <Button variant="primary" block onClick={this.handlebutton.bind(this,'Manual')}>Manual Entry</Button>
                </ButtonToolbar>
            </div>
        ); 
    }


    _scan() {
        this.setState({scanning: !this.state.scanning});
    }

    _onDetected(result) {

        console.log("!DETECTED");

        console.log(result);

        this.setState({results: result});
        this.setState({scanning: false});
              
        //this.state.path+"?sku="+this.state.sku
        this.props.history.push(this.state.path+"?sku="+result);
    }

  handlebutton(which)
  {

        switch(which)
        {
            case "Manual":
                this.props.history.push("/ManualEntry?path="+this.state.path);
            break;

            case "Exit":
                console.log("Exit request to "+this.state.returnpath);
                this.props.history.push(this.state.returnpath);
            break;

            default:
            break;
        }
  }
}

export default withRouter(ScannerControl2)