import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import {Button,Modal} from 'react-bootstrap';

export class Okmsg extends Component {
  render() {
    return (
      <div className="okmsg">
        
        <Modal.Dialog className="okmsg">
              <Modal.Header closeButton className="okmsg">
                <Modal.Title>SUCCESS</Modal.Title>
              </Modal.Header>

              <Modal.Body className="okmsg">
                <p>UPDATE SUCCESSFUL!</p>
              </Modal.Body>

            </Modal.Dialog>

            <Button variant="primary" onClick={this.handleSubmit.bind(this)} > 
            Continue
            </Button>

      </div>
    )
  }

  handleSubmit()
  {
    this.props.history.go(-1);
  }

}

export default withRouter(Okmsg)
