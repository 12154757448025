import React, { Component } from 'react'
import axios from 'axios';
import {Dropdown} from 'react-bootstrap';

export class BrandsDropDown extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
            selected: null,

        }
    }


    
  componentDidMount()
  {
    

    console.log("BrandsDropDrown.componentDidMount()");
    console.log(this.props);  

    axios.get(this.props.baseUrl+'brands/', {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      this.setState({records: response.data.records});
      
     // console.log(response.data.records);

      if(this.props.title==null)
      {
        this.setState({selected: 'NONE'});
      }
      else
      {
        var title = this.props.title;
        var pthis = this;
        response.data.records.forEach(function(element)
        {
       //     console.log(element);
            if(element.id === title)
            {
                pthis.setState({selected: element.name});
            }

        });


   

      }   

      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  onUpdateNameByKey(newkey)
  {
    console.log(this);
    var pthis=this;
    this.state.records.forEach(function(element)
    {
        if(element.id === newkey)
        {
            pthis.setState({selected: element.name});
        }

    });
  }

  onChange(e)
  {

  }

  onSelect(e)
  {
    //this.setState({selected: e})
    this.onUpdateNameByKey(e);
    this.props.onChangeBrand(e);
   console.log(e);
  }

  render() {

    if(this.state.records == null)
    {   
        return (<div>DD IS NULL</div>);
    }

    //console.log(this.state.records)

    return (
      <div>
        
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {this.state.selected}
          </Dropdown.Toggle>

          <Dropdown.Menu >
          
          {
            this.state.records.map(element =>
            <Dropdown.Item onSelect={this.onSelect.bind(this,element.id)} key={element.id}>{element.name}</Dropdown.Item>
           )  
          }   

        </Dropdown.Menu>
        </Dropdown>


      </div>
    )
  }
}

export default BrandsDropDown
