import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom';
import {Button,ButtonToolbar,ButtonGroup,Image} from 'react-bootstrap';
import homeicon from '../images/home-button-round-blue.png';
import backicon from '../images/back.png';


export class MenuHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    }
  }

  componentDidUpdate()
  {
    if(this.props.location!=null && this.state.redirect!=null && (this.props.location.pathname===this.state.redirect))
    {
      this.setState({redirect: null})
    }
  }
  
  render() {

    if(this.props.location!=null && this.state.redirect!=null && (this.props.location.pathname!==this.state.redirect))
    {
      return(
      <Redirect to={this.state.redirect} />
      );
    }
    
    if(this.props.history.length !== 1)
    {
    return (
      <div>
          <div className="HeaderNav">
          <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup className="mr-2" aria-label="First group">
              <Button><Image src={homeicon} className="navbutton"  onClick={this.handlebutton.bind(this,'home')} /></Button>
              <Button><Image src={backicon} className="navbutton"  onClick={this.handlebutton.bind(this,'back')} /></Button>
              <Button onClick={this.handlebutton.bind(this,'actions')} >A</Button>
              <Button onClick={this.handlebutton.bind(this,'reports')} >R</Button>
              
            </ButtonGroup>
          </ButtonToolbar>

          <p>Logged in as {this.props.user}</p>
          </div>
      </div>
    )
    }
    else
    {
      return (  <div>
        <div className="HeaderNav">
        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup className="mr-2" aria-label="First group">
            <Button onClick={this.handlebutton.bind(this,'close')} >CLOSE</Button>  
          </ButtonGroup>
        </ButtonToolbar>
        </div>
    </div> );
    }



  }

handlebutton(e)
{
  if(e==='home')
    this.setState({redirect: '/'});

  if(e==='back')
  {
      this.props.history.goBack();
  }

  if(e==='actions')
    this.setState({redirect: '/Actions'});

  if(e==='reports')
    this.setState({redirect: '/ReportsMenu'});

    if(e==='close')
    {
      window.close();
    }
   

}

}

export default withRouter(MenuHeader)
