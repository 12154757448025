import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';

export class TicketLine extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
        }
    }
  
  componentDidMount()
  {
    axios.get(this.props.baseUrl+'ticketlineparent/'+this.props.id+"/" , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
        this.setState({records: response.data.records});
        this.forceUpdate();
        })
        .catch(function (error) {
        console.log(error);
        })
  }

  render() {

    console.log(this);
    if(this.state.records==null)
         return(<pre>NULL</pre>);
 
    return (
      <div>
        <pre>
            {
                this.state.records.map(function(element){
                    return element.name + " "+element.count+"x "+element.price+"\n";
                  })
            }
       
        </pre>
      </div>
    )
  }
}

export default withRouter(TicketLine)
