import React, { Component } from 'react'
import {Button,Modal} from 'react-bootstrap';

export class Modalmsg extends Component {


  handleSubmit()
  {
    this.props.ret();
  }


  
  render() {
    return (
      <div>
        
        <Modal.Dialog>
              <Modal.Header>
                <Modal.Title>{this.props.Title}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>{this.props.msg}</p>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleSubmit.bind(this)}>OK</Button>
              </Modal.Footer>
            </Modal.Dialog>

      </div>
    )
  }
}

export default Modalmsg
