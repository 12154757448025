import React, { Component } from 'react'

import { withRouter } from 'react-router-dom';
import SupplierList from './SupplierList';
import axios from 'axios';
import AddSupplier from './AddSupplier';
import { Button } from 'react-bootstrap';


export class ShowSuppliers extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            records: null,
        };
    }

    componentDidMount()
    {
        console.log(this.props.jwt);
        
        this.dodb();


    }

    dodb()
    {

        axios.get(this.props.baseUrl+'suppliers/' , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
            this.setState({records: response.data.records});
            this.forceUpdate();
        })
        .catch(function (error) {
            console.log(error);
        })

    }

    handleaddsupplier()
    {
        this.props.history.push('/Suppliers/AddEdit?add=1')
    }

    //  <AddSupplier  baseUrl={this.props.baseUrl} jwt={this.props.jwt} onreload={this.onreload.bind(this)}  />
    
   


    render() 
    {
        return (
            <div>
                
            {(this.props.authlevel>1) &&
            <Button variant="danger" block  onClick={this.handleaddsupplier.bind(this)}>Add Supplier</Button>    
            }

            <h1>Existing Suppliers</h1>
            <SupplierList items={this.state.records} baseUrl={this.props.baseUrl} jwt={this.props.jwt} onreload={this.onreload.bind(this)}  >  </SupplierList>
            </div>
        );
    }

    onreload()
    {
        this.dodb();
        this.forceUpdate();
    }
}

export default withRouter(ShowSuppliers)


    