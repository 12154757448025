import React, { Component } from 'react'
import {Button,Form} from 'react-bootstrap';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from 'axios';

export class EditAddECRUser extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
           add: null,
           records: null,

           userId: "",
           userName: "",
           userPass: "",
           userEmail: "",
           authLevel: "",
        };
    }

    componentDidMount()
    {
      const searchvalues = queryString.parse(this.props.location.search); 
      this.setState({add: searchvalues.add});
      this.setState({id: searchvalues.id});
      
      console.log("EdditAddMount");
      console.log(searchvalues);

      if(searchvalues.add !== "1" )
      {
        console.log("Getting user");
        axios.get(this.props.baseUrl+'users/'+searchvalues.id+'/' , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
            this.setState({records: response.data.records});
            this.setState({userName: response.data.records[0].userName});
            this.setState({userId: response.data.records[0].userId});
            this.setState({userEmail: response.data.records[0].userEmail});
            this.setState({userPass: response.data.records[0].userPass});
            this.setState({authLevel: response.data.records[0].authLevel});
            
            
            this.forceUpdate();
        })
        .catch(function (error) {
            console.log(error);
        })
      }

    }

    handleSubmit()
    {
      if(this.state.add==="1")
      {
        //Add new case


        console.log(this.state.ecrid);

        axios(
          {
              method: 'post',
              url: this.props.baseUrl+"users/",
              data: {
                userId: this.state.userId,
                userName: this.state.userName,
                userEmail: this.state.userEmail,
                userPass: this.state.userPass,
                authLevel: this.state.authLevel,
              },
              headers:
              {
                Authorization: `Bearer `+this.props.jwt,
              }
          }).then(response => 
          {    
              console.log("SUBMIT RESPOSE");
              this.props.history.push('/Users/ShowAll');
          })
          .catch(function (error) 
          {
         
            console.log("SUBMIT ERROR");       
            console.log(error);
          })


      } 
      else
      {
        axios(
          {
              method: 'patch',
              url: this.props.baseUrl+"users/"+this.state.userId+"/",
              data: {
                userId: this.state.userId,
                userName: this.state.userName,
                userEmail: this.state.userEmail,
                userPass: this.state.userPass,
                authLevel: this.state.authLevel,
              },
              headers:
              {
                Authorization: `Bearer `+this.props.jwt,
              }
          }).then(response => 
          {    
              console.log("SUBMIT RESPOSE");
              this.props.history.push('/Users/ShowAll');
          })
          .catch(function (error) 
          {
         
            console.log("SUBMIT ERROR");       
            console.log(error);
          })



      } 
    }

    onChangeName(e)
    {
      this.setState({userName: e.target.value});
    }

    onChangePass(e)
    {
      this.setState({userPass: e.target.value});
    }

    onChangeEmail(e)
    {
      this.setState({userEmail: e.target.value});
    }

    onChangeAuthlevel(e)
    {
      this.setState({authLevel: e.target.value});
    }

    render() 
    {

      if(this.state.add===0 && this.state.records === null)
      return(
          <div> 
          <h2>Fetching data</h2>
          <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
          </div>
      );

        return (
            <div>
            <h3>Users</h3>

            <Form>

                <Form.Group controlId="UserId">                
                    <Form.Label>ID</Form.Label>
                    <Form.Control type="text" value={this.state.userId} readOnly={true} /> 
                </Form.Group>

                <Form.Group controlId="UserName">                
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={this.state.userName} onChange={this.onChangeName.bind(this)}/> 
                </Form.Group>

                <Form.Group controlId="UserEmail">                
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" value={this.state.userEmail} onChange={this.onChangeEmail.bind(this)}/> 
                </Form.Group>

                <Form.Group controlId="authLevel">                
                    <Form.Label>Auth Level</Form.Label>
                    <Form.Control type="text" value={this.state.authLevel} onChange={this.onChangeAuthlevel.bind(this)}/> 
                </Form.Group>


                <Button variant="primary" onClick={this.handleSubmit.bind(this)} > 
                    Submit
                </Button>
 
            </Form>

            </div>
        );
    }
}

export default withRouter(EditAddECRUser)


    