import React, { Component } from 'react'
import {Button,Form} from 'react-bootstrap';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from 'axios';

export class EditAddSupplier extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
           add: null,
           records: null,
           supplierId: "",
           supplierName: "",
        };
    }

    componentDidMount()
    {
      const searchvalues = queryString.parse(this.props.location.search); 
      this.setState({add: searchvalues.add});
      this.setState({id: searchvalues.id});
      
      console.log("EdditAddMount");
      console.log(searchvalues);

      if(searchvalues.add !== "1" )
      {
        console.log("Getting supplier");
        axios.get(this.props.baseUrl+'suppliers/'+searchvalues.id+'/' , {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
            this.setState({records: response.data.records});
            this.setState({supplierName: response.data.records[0].name});
            this.setState({supplierId: response.data.records[0].id});
            
            
            
            this.forceUpdate();
        })
        .catch(function (error) {
            console.log(error);
        })
      }

    }

    handleSubmit()
    {
      if(this.state.add==="1")
      {
        //Add new case


        console.log(this.state.ecrid);

        axios(
          {
              method: 'post',
              url: this.props.baseUrl+"suppliers/",
              data: {
                name: this.state.supplierName,
              },
              headers:
              {
                Authorization: `Bearer `+this.props.jwt,
              }
          }).then(response => 
          {    
              console.log("SUBMIT RESPOSE");
              this.props.history.push('/Suppliers/ShowAll');
          })
          .catch(function (error) 
          {
         
            console.log("SUBMIT ERROR");       
            console.log(error);
          })


      } 
      else
      {
        axios(
          {
              method: 'patch',
              url: this.props.baseUrl+"suppliers/"+this.state.supplierId+"/",
              data: {
                id: this.state.supplierId,
                name: this.state.supplierName,
              },
              headers:
              {
                Authorization: `Bearer `+this.props.jwt,
              }
          }).then(response => 
          {    
              console.log("SUBMIT RESPOSE");
              this.props.history.push('/Suppliers/ShowAll');
          })
          .catch(function (error) 
          {
         
            console.log("SUBMIT ERROR");       
            console.log(error);
          })



      } 
    }

    onChangeName(e)
    {
      this.setState({supplierName: e.target.value});
    }

   

    render() 
    {

      if(this.state.add===0 && this.state.records === null)
      return(
          <div> 
          <h2>Fetching data</h2>
          <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
          </div>
      );

        return (
            <div>
            <h3>Suppliers</h3>

            <Form>

                <Form.Group controlId="SupplierId">                
                    <Form.Label>ID</Form.Label>
                    <Form.Control type="text" value={this.state.supplierId} readOnly={true} /> 
                </Form.Group>

                <Form.Group controlId="supplierName">                
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={this.state.supplierName} onChange={this.onChangeName.bind(this)}/> 
                </Form.Group>

                <Button variant="primary" onClick={this.handleSubmit.bind(this)} > 
                    Submit
                </Button>
 
            </Form>

            </div>
        );
    }
}

export default withRouter(EditAddSupplier)


    