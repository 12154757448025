import React, { Component } from 'react'
import axios from 'axios';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import MagicTable from "../Functional/magictable";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import {multiWordFilter,barcodeFilter,mynumberfilter} from "../multiwordfilter.js";


import {
  textFilter,
  selectFilter,
  dateFilter,
  numberFilter,
  Comparator
} from "react-bootstrap-table2-filter";



export class Top100Value extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            sku: null,
            records: null,
            from: null,
            to: null,
            title: null,
            selectOptionssupplier: null,
            msg: "",
            progress: 0,
        }
    }

  componentDidMount()
  {
   
    this.setState({title: 'TOP 100 Report'});

    axios.get(this.props.baseUrl+'suppliers/', {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      console.log(response.data.records);

      var a=[];

      response.data.records.forEach(function(element)
      {
        a.push({value: element.name, label: element.name});

      });

      this.setState({selectOptionssupplier: a});

    });


    axios.get(this.props.baseUrl+'department/', {headers: { Authorization: `Bearer `+this.props.jwt }})
    .then(response => {
      console.log(response.data.records);

      var a=[];

      response.data.records.forEach(function(element)
      {
        a.push({value: element.name, label: element.name});

      });

      this.setState({selectOptionsdepartments: a});

    });

  
    
    var url="";
    url = this.props.baseUrl+'sales/zerostock?';
   
    const searchvalues = queryString.parse(this.props.location.search)
    this.setState({from: searchvalues.from});
    this.setState({to: searchvalues.to});
    axios.get(url+searchvalues.from+'&end='+searchvalues.to , {headers: { Authorization: `Bearer `+this.props.jwt },
      onDownloadProgress: (progressEvent) => {
        this.setState({progress: progressEvent.loaded});
      }
    })
    .then(response => {
      this.setState({records: response.data.records});
      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })


/*
var sql = "select stock_details.*,lastsales.*,suppliers.name as suppliername, departments.name as departmentname from lastsales,stock_details LEFT JOIN suppliers on suppliers.id = stock_details.supplierid  left join departments on departments.id=stock_details.department where lastsales.barcode=stock_details.barcode;";
var presql = " create TEMPORARY table lastsales as select barcode, sum(case when date>= CURDATE() - INTERVAL 7 DAY THEN delta ELSE 0 END) as last7, sum(case when date>= CURDATE() - INTERVAL 14 DAY THEN delta ELSE 0 END) as last14, sum(case when date>= CURDATE() - INTERVAL 30 DAY THEN delta ELSE 0 END) as last30, sum(case when date>= CURDATE() - INTERVAL 90 DAY THEN delta ELSE 0 END) as last90, sum(case when date>= CURDATE() - INTERVAL 365 DAY THEN delta ELSE 0 END) as last365,sum(case when date>= CURDATE() - INTERVAL 7 DAY THEN price ELSE 0 END) as pricelast7, sum(case when date>= CURDATE() - INTERVAL 14 DAY THEN price ELSE 0 END) as pricelast14, sum(case when date>= CURDATE() - INTERVAL 30 DAY THEN price ELSE 0 END) as pricelast30, sum(case when date>= CURDATE() - INTERVAL 90 DAY THEN price ELSE 0 END) as pricelast90, sum(case when date>= CURDATE() - INTERVAL 365 DAY THEN price ELSE 0 END) as pricelast365 from sales group by barcode;";

    var url = this.props.baseUrl+'execute2/';   
    var method='post';

    
    axios(
      {
        url: url,
        method: method,
        headers: { Authorization: `Bearer `+this.props.jwt },
        data: {SQL: sql, preSQL: presql}
    })
    .then(response => {
      this.setState({records: response.data.records});
      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    });
*/
   }

  render() {

    if( this.state.records === null)
      return(
          <div> 
          <h2>Generating your report</h2>
          <p>Please wait......</p>
          <p>Loaded {this.state.progress} bytes</p>
          <ReactLoading type={'spinningBubbles'} color={'#f00'} height={'50%'} width={'100%'} />
          </div>
      );
 
    const columns = [{
      dataField: 'id',
      text: 'id',
      hidden: true
  }, {
    dataField: 'barcode',
    text: 'Barcode',
    sort: true,
    sortValue: (cell, row) => Number(cell),
    filter: textFilter({
      onFilter: barcodeFilter()
    }),
  }, {
    dataField: 'mpn',
    text: 'mpn',
    sort: true,
    filter: textFilter(),
  }
  ,{
    dataField: 'stock_name',
    text: 'Stock name',
    sort: true,

    filter: textFilter({
      onFilter: multiWordFilter
    })

  }
  ,{
    dataField: 'selling_price',
    text: 'Selling price',
    sort: true,
    sortValue: (cell, row) => Number(cell) ,
    filter: numberFilter(),
  }
  ,{
    dataField: 'company_price',
    text: 'Cost price',
    sort: true,
    sortValue: (cell, row) => Number(cell),
    filter: numberFilter(),
  }
  ,{
    dataField: 'stock_quatity',
    text: 'In Stock',
    sort: true,
    filter: numberFilter(),
  }

  ,{
    dataField: 'sum(delta)',
    text: 'Sold amount',
    sort: true,
    sortValue: (cell, row) => Number(cell),
    filter: numberFilter(), 

  },
  {
    dataField: 'sum(price)',
    text: 'Sold value',
    sort: true,
    sortValue: (cell, row) => Number(cell) ,
    filter: numberFilter(),
  },
  {
    dataField: 'lastsale',
    text: 'lastsale',
    sort: true, 
    filter: dateFilter(),
    
  }
  ,
  {
    dataField: 'date',
    text: 'last stock take',
    sort: true, 
    filter: dateFilter(),
  }
  ,
  {
    dataField: 'departmentname',
    text: 'Department',
    sort: true,
    filter: selectFilter({
      options: this.state.selectOptionsdepartments
    })
  },
  {
    dataField: 'suppliername',
    text: 'Supplier',
    sort: true,
    filter: selectFilter({
      options: this.state.selectOptionssupplier
    })
  }
];

      return(
      <div>

      <h3>This report list total sales for all time</h3>

      <MagicTable
          {...this.props}
          data={this.state.records}
          columns={columns}
        />

      
      </div>
      
      )
 
     
  }
}

export default Top100Value