import React, { Component } from 'react'

import {Button,Form} from 'react-bootstrap';

import { withRouter } from 'react-router-dom';
import ItemList from '../ItemList';
import axios from 'axios';

export class ItemSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: null,
            search: '',
            search2: '',
        };

       
    }

    componentDidMount()
    {
        
    }

    handleSearchChange (e) {
        this.setState({search: e.target.value});
      
      }


      handleSearchChange2 (e) {
        this.dosearch();
      }

      dosearch()
      {
        axios.get(this.props.baseUrl+'productsearch/name/?name='+this.state.search, {headers: { Authorization: `Bearer `+this.props.jwt }})
        .then(response => {
          this.setState({records: response.data.records});
          this.forceUpdate();
        })
        .catch(function (error) {
          console.log(error);
        })
      }

    render() {

            return (
                <div>
                <h2>Search by name</h2>
                <Form onSubmit={this.onFormSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Button onClick={this.handleSearchChange2.bind(this)}>SEARCH</Button>
                    <Form.Label>Search term</Form.Label>
                    <Form.Control autoFocus={true} type="text" placeholder="Partial name" onChange={this.handleSearchChange.bind(this)}  />
      
                </Form.Group>
        
                </Form>

                <ItemList key='123' items={this.state.records} name='NAME' cols='2' >  </ItemList>
 
                </div>

            )
        
    }

}

export default  withRouter(ItemSearch)