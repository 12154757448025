/*
// Usage

 onChangeReason(e)
   {
      this.setState({reason: e});
   }

 <DropDownConstant apitarget="writeoffreasons" baseUrl={this.props.baseUrl} jwt={this.props.jwt} selected={this.state.brandid} onChangeDropDown={this.onChangeReason.bind(this)}/>  

 apitarget must point to a endpoint that returns a map of id,name

*/


import React, { Component } from 'react'
import axios from 'axios';
import {Dropdown} from 'react-bootstrap';

export class DropDownConstant extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: null,
            selected: null,
        }
    }

  componentDidMount()
  {
    
    axios.get(this.props.baseUrl+this.props.apitarget, {headers: { Authorization: `Bearer `+this.props.jwt }})
      .then(response => {
        this.setState({records: response.data.records});
      
        if(this.props.selected==null)
        {
          this.setState({selected: 'NONE'});
        }
        else
        {
          var selected = this.props.selected;
          var pthis = this;
          response.data.records.forEach(function(element)
          {
              if(element.id === selected)
              {
                  pthis.setState({selected: element.name});
              }
          });
      }   

      this.forceUpdate();
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  onUpdateNameByKey(newkey)
  {
    //console.log(this);
    var pthis=this;
    this.state.records.forEach(function(element)
    {
        if(element.id === newkey)
        {
            pthis.setState({selected: element.name});
            if(pthis.props.onChangeDropDownValue !== undefined)
            {
              pthis.props.onChangeDropDownValue(element.name);
            }
        }

    });
  }

  onSelect(e)
  {
    this.onUpdateNameByKey(e);
    this.props.onChangeDropDown(e);
  }

  render() {

    if(this.state.records == null)
    {   
        console.log("RECORDS ARE NULL");
        return (<div>DD IS NULL</div>);
    }

    return (
      <div>
        
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {this.state.selected}
          </Dropdown.Toggle>
          <Dropdown.Menu >
          
          {
            this.state.records.map(element =>
            <Dropdown.Item onSelect={this.onSelect.bind(this,element.id)} key={element.id}>{element.name}</Dropdown.Item>
           )  
          }   

        </Dropdown.Menu>
        </Dropdown>

      </div>
    )
  }
}

export default DropDownConstant
