import React, { useState } from "react";
import { DateRangePicker,DateRange,Calendar, DefinedRange} from "react-date-range";
import { defaultStaticRanges } from "./defaultranges";
import { format } from "date-fns";
import {Button,ButtonToolbar} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "./Datepicker.css";

import PropTypes from "prop-types";

const DateRangeSelector = ({ ranges, onChange, onSubmit, ...rest }) => {
     const [selectedDateRange, setSelectedDateRange] = useState({
          startDate: new Date(),
          endDate: new Date(),
          disabled: false,
          key: "selection"
     });
     const [show, setShow] = useState(false);

     function formatDateDisplay(date, defaultText) {
          if (!date) return defaultText;
          return format(date, "MM/DD/YYYY");
     }

     const handleSelect = ranges => {
          setSelectedDateRange(ranges.selection);
          console.log(ranges.selection);
     };

     // const onClickDone = () => {
     //      onSubmit(selectedDateRange);
     //      setShow(true);
     // };

     const onClickClear = () => {
          setSelectedDateRange({
               startDate: new Date(),
               endDate: new Date(),
               key: "selection"
          });
          setShow(false);
     };

     const handlebutton = (which,button) =>
    {
        
        if(which==="accept")
        {
        
            onSubmit(selectedDateRange);
  
        }
        if(which==="Cancel")
        {
            onSubmit(null);
        }
        if(which=="clear")
        {
            onClickClear();
        }
    }

console.log(selectedDateRange.startDate);

     return (
          <React.Fragment>
               <div className="shadowx d-inline-blockx">

             
               <DateRange
                editableDateInputs={true}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                ranges={[selectedDateRange]}/>

                <DefinedRange
                onChange={handleSelect}
                ranges={[selectedDateRange]}
                />;



                {/*
                <h1> Choose a date range</h1>
                    <DateRangePicker
                         onChange={handleSelect}
                         showSelectionPreview={false}
                         moveRangeOnFirstSelection={false}
                         months={1}
                         ranges={[selectedDateRange]}
                         direction="horizontal"
                    />
                */}

                    {/*
                    <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
                         <button
                              className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
                              onClick={() => setShow(true)}
                         >
                              Done
                         </button>
                         <button
                              className="btn btn-transparent text-danger rounded-0 px-4"
                              onClick={onClickClear}
                         >
                              Clear
                         </button>
                    </div>
                    */}

        {
        <ButtonToolbar className='buttonblockfloat'>
          <Button variant="primary" onClick={handlebutton.bind(this,'accept')} key={1}>Accept</Button>
          <Button variant="primary" onClick={handlebutton.bind(this,'clear')} key={3}>Clear</Button>
          <Button variant="danger" onClick={handlebutton.bind(this,'Cancel')} key={2}> Cancel</Button>        
          </ButtonToolbar>
                }

               </div>
                
               {show&&<div className="h-100 mt-3 alert alert-transparent">
                    <p className="my-auto d-inline">Start Date :{" "}
                    {formatDateDisplay(selectedDateRange.startDate)}{" | "}
                    End Date :{" "}
                    {formatDateDisplay(selectedDateRange.endDate)}
                    </p>
                    <button className="mb-1 btn btn-transparent text-danger" onClick={() => setShow(false)} variant="outline-success"> Close</button>
               </div>}
          </React.Fragment>
     );
};

DateRangeSelector.defaultProps = {
     ranges: defaultStaticRanges
};

DateRangeSelector.propTypes = {
     /**
      * On Submit
      */
     onSubmit: PropTypes.func
};



export default DateRangeSelector;
